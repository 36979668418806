import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Divider,
  Typography,
  Grid,
  Stack,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import { Colors } from "../../utils/constants";
import Nav from "../../components/Nav/MainNav";
import DropDownComponent from "../../components/browserJournalblocks/dropDownComponent";
import { styled } from "@mui/material/styles";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import TabView from "../../components/browserJournalblocks/TabView";
import Publisher from "../../components/browserJournalblocks/Publisher";
import CollectionsComponentOfBrowser from "../../components/leftBarComponent/collectionsOfBrowser";
import SearchFilterComponentOfBrowser from "../../components/leftBarComponent/searchFilterOFBrowser";
import LatestUpdates from "../../components/browserJournalblocks/latestUpdates";
import Packages from "../../components/browserJournalblocks/package";
import BrowseJournal from "./browseJournal";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AlphabeticalFilter from "../../components/alphabeticalFilter/AlphabeticalFilter";
import {
  booksAutoSuggestion,
  browseJournalStartingWith,
  browserAutoSuggestion,
  browserJournalContainingSearch,
  browserJournalContainingSearchOne,
  browserJournalSearch,
  getAllBrowserJournalOnClick,
  handleAlphabet,
  publisherAutoSuggestion,
  resourceSettingAlert,
} from "../../api/browseJournal";
import Pagination from "../../components/pagination";
import {
  clearAllFilters,
  updateBrowserPage,
  updateBrowserRow,
  updatecollection,
  updateselectAlphabet,
  
} from "../../store/slice/browseFilterSlice";
import {
  updateBrowserPage as updateBrowserPagePublisher,
  updateCurrentUpdate,
  updatepublishercollection,
} from "../../store/slice/browserFilterSlicePublisher";
import { useDispatch, useSelector } from "react-redux";
import {
  browseJournalSearchResult,
  setSearchKeyword,
  updateBrowseJournalSearch,
} from "../../store/slice/browseJournal";
import { setSearchKeywordPublisher } from "../../store/slice/browserPublisher";
import { alertsAndFav, setKeyword } from "../../store/slice/browseJournal";
import { getSelectedKeyWord } from "../../store/slice/browseJournal";
import { useAppSelector } from "../../store/hooks";
import objectToQueryString from "./objToQuery";
import { loginFail } from "../../store/slice/loginSlice";
import { goToTop, isPreferenceAddedSubjectAndDataType, showIsProfileToast } from "../../utils/helper";
import SearchFilterComponentOfPublisher from "../../components/leftBarComponent/searchFilterOfPublisher";
import SearchFilterComponentOfPackage from "../../components/leftBarComponent/searchFilterOfPackage";
import useClickOutsideListner from "../../hooks/ClickOutsideListner.hook";
import CollectionsComponentOfPublisher from "../../components/leftBarComponent/collectionOfPublisher";
import { tabSwitchBrowserJournal } from "../../store/slice/browseJournal";
import { updateselectAlphabetPublisher } from "../../store/slice/browserFilterSlicePublisher";
import AlphabeticalFilterForAuthor from "../../components/alphabeticalFilter/AlphabeticalFilterForAuthor";
import {
  updateBrowserSearch_term,
  updateBrowserSearch_type,
} from "../../store/slice/browseFilterSlicePackage";
import { clearAllFilters as clearAllFiltersPublisher } from "../../store/slice/browserFilterSlicePublisher";
import { clearAllFilters as clearAllFiltersPackage } from "../../store/slice/browseFilterSlicePackage";
import { usagelogApi, usageReport } from "../../api/usageReportApi";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { AntSwitch } from "../../components/basicSearchTopBar/basicSearchTopBar";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { clearDataTypeFilters, updateDataType } from "../../store/slice/searchSliceBrowseJournal";
import { setModal } from "../../store/slice/modalSlice";
import { useGetMySearchPreference } from "../../api/Preferences/Subjects.api";
import { GetMyPreferenceData } from "../../types/GetMySubjects.types";
import { updateSubjectType } from "../../store/slice/searchSlice";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const year = ["Starting With", "Containing"];

const text = ["Title A-Z", "By Publisher", "By Package"];
const sortData = [
  "ALL",
  "(0-90)",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const BrowseJournalTableSearch: React.FC = () => {
  const {
    ref,
    showList: showAutoComplete,
    setShowList: setShowAutoComplete,
  } = useClickOutsideListner(false);

  const [autoCompleteData, setAutoCompleteData] = useState<string[] | null>(
    null
  );

  const filter = createFilterOptions<any>();

  const [selectedLetterTags, setSelectedLetterTags] = React.useState(
    Array(28).fill(false)
  );

  const [selectedLetterTagsPublisher, setSelectedLetterTagsPublisher] =
    React.useState(Array(28).fill(false));

  const [selectedLetterTagsPackage, setSelectedLetterTagsPackage] =
    React.useState(Array(28).fill(false));

  const searchContainOrStartWith = useSelector(
    (state: any) => state.browseJournalSearch.containStartingWith
  );
  const [message, setMessage] = useState({ messages: "", open: false });

  const searchContainOrStartWiths = useSelector((state: any) => state);

  const value: any = useSelector(
    (state: any) => state.browseJournalSearch.tabSwitch
  );

  const [tabIndex, setTabIndex] = useState<any>(0);

  const publisherResults = useSelector(
    (state: any) =>
      state.browserPublisher?.browserResult?.grouped?.publisher_name_tk
  );

  useEffect(() => {
    // TO LAND ON LANDING PAGE
    const tab: string = sessionStorage.getItem("getTabSwitch") ?? "0";
    setTabIndex(parseInt(tab));
    dispatch(updateBrowseJournalSearch(true));

    return () => {
      dispatch(updateselectAlphabet(""));
      dispatch(clearAllFiltersPublisher());
      dispatch(clearAllFiltersPackage());
      // dispatch(clearAllFilters());
    };
  }, []);

  const getKeywords = useSelector((state: any) => state);

  const [selectedSortTag, setSelectedSortTag] = React.useState<string[]>([]);
  const [expanded, setExpanded] = React.useState(false);
  const [selectedTag, setSelectedTag] = React.useState<String>("");
  const [selectedTagPublisher, setSelectedTagPublisher] =
    React.useState<String>("");

  const [selectedTagPackage, setSelectedTagPackage] =
    React.useState<String>("");

  const [apipage, setApiPage] = React.useState(1);
  const [sort, setSort] = React.useState("");
  const [filters, setFilters] = React.useState<any>("");
  const [filtersPublisher, setFiltersPublisher] = React.useState<any>("");
  const [filtersPackage, setFiltersPackage] = React.useState<any>("");
  const [atoz, setatoz] = useState(false);
  const [atozPublisher, setatozPublisher] = useState(false);
  const [atozPackage, setatozPackage] = useState(false);

  const [publisher, setPublisher] = useState(false);
  const [selectFilters, setSelectFilters] = useState(false);
  const [journalSearchResults, setJournalSearchResults] = React.useState<any[]>(
    []
  );
  const [keyWord, setKeyWord] = React.useState("");
  const [keyWordPublisher, setKeyWordPublisher] = React.useState("");
  const [keyWordPackage, setKeyWordPackage] = React.useState("");

  const [keyWords, setKeyWords] = React.useState<any[]>([]);
  const [keyWordsPublisher, setKeyWordsPublisher] = React.useState<any[]>([]);
  const [keyWordsPackage, setKeyWordsPackage] = React.useState<any[]>([]);

  const [handleEnter, setHandleEnter] = React.useState(false);
  const [autoSearch, setAutoSearch] = useState<any>([]);
  const [autoSearchPublisher, setAutoSearchPublisher] = useState<any>([]);
  const [myPersonalLibrary, setMyPersonalLibrary] = useState(false);
  const [myPersonalLibraryPublisher, setMyPersonalLibraryPublisher] =
    useState(false);
  const [isOpen, setIsOpen] = useState(true); // To control the opening and closing of Autocomplete
  const [isOpenPublisher, setIsOpenPublisher] = useState(true); // To control the opening and closing of Autocomplete
  const [myLibrary, setMyLibrary] = useState(false);
  const [collectionFilter, setCollectionFilter] = useState("");
  const isPreferenceEnabled = sessionStorage.getItem("preference") === "true";
  const [isPreference, setIsPreference] = useState(isPreferenceEnabled);
  const [isProfileUser, setIsProfileUser] = useState(true);
  const user_id = useAppSelector((state) => state.login.user_id);

  const { data: myPreference, refetch } = useGetMySearchPreference(
    user_id,
    isPreference
  );


  const openAccess = useAppSelector(
    (state) => state.browserFilterSlicePublisher.currentUpdate
  );
  const selectedFilters: any = useSelector(
    (state: any) => state.browseFilterSlice
  );
  const dispatch = useDispatch();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(clearAllFilters());
    setFilters("");
    setFiltersPublisher("");
    setKeyWordPublisher("");
    dispatch(clearAllFiltersPackage());
    dispatch(clearAllFiltersPublisher());
    dispatch(setSearchKeyword([]));
    dispatch(setSearchKeywordPublisher([]));
    setKeyWordsPackage([]);
    dispatch(updateBrowserSearch_term([]));
    setKeyWordPackage("");
    dispatch(tabSwitchBrowserJournal(newValue));
    dispatch(updateCurrentUpdate(!openAccess));
    if (
      selectedFilters.collectionsValueJournal !== "My Library Collections" &&
      selectedFilters.collectionsValueJournal !== "Consortia Collections"
    ) {
      dispatch(updatecollection(""));
    }
    dispatch(updatepublishercollection(""));
    // @ts-ignore
    sessionStorage.setItem("getTabSwitch", newValue);
    if (newValue == 0) {
      logBasicSearchUsageData(76);
    } else if (newValue == 1) {
      logBasicSearchUsageData(77);
    } else if (newValue == 2) {
      logBasicSearchUsageData(78);
    }
    setTabIndex(newValue);
    dispatch(updateCurrentUpdate(false));
  };

  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const selectedCollectionFromBrowseAz = useAppSelector(
    (state: any) => state.browseFilterSlice
  );

  const selectedCollectionFromBrowseByPublisher = useAppSelector(
    (state: any) => state.browserFilterSlicePublisher
  );

  const selectedPublisherFilters: any = useSelector(
    (state: any) => state.browserFilterSlicePublisher
  );

  function getContextId(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "Consortia Collections":
        return 2;
      case "My Library Collections":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOS Collections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }
  const contextIdFromAz = getContextId(
    selectedCollectionFromBrowseAz?.collectionsValueJournal
  );

  const contextIdFromByPublisher = getContextId(
    selectedCollectionFromBrowseByPublisher?.collectionsValuePublisher
  );

  let contextId: any;
  if (tabIndex === 0) {
    contextId = contextIdFromAz;
  } else if (tabIndex === 1) {
    contextId = contextIdFromByPublisher;
  } else {
    contextId = null;
  }

  const logBasicSearchUsageData = (usageActionID: any) => {
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;
    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  const data: any = useAppSelector(
    (state) => state.browseJournalSearch.browserResult
  );

  const packageHits = useSelector(
    // @ts-ignore
    (state) => state?.browsePackage?.browserResult
  );
  const setAlphabet: any = useSelector((state: any) => state);

  const enabledTags = useSelector(
    (state: any) => state.browseJournalSearch.knownAlphasearch
  );

  const handleTag = (tag: string) => {
    if (selectedSortTag.includes(tag)) {
      setSelectedSortTag(() => selectedSortTag.filter((data) => data !== tag));
    } else if (selectedSortTag.length) {
      setSelectedSortTag((prev) => [...prev, tag]);
    } else {
      setSelectedSortTag([tag]);
    }
  };
  const onChangeKeyword = async (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setKeyWord(e.target.value);
    let searchedLetters: any = "";
    if (e.target.value.length >= 3) {
      // @ts-ignore
      let searchedArrayLetters = e.target.value.split(" ");
      searchedArrayLetters?.map((element: string) => {
        searchedLetters += `${element}\\ of\\ `;
      });

      let apiJournalData = {
        resource_name_tk: `${searchedLetters.slice(0, -6)}*`,
      };

      let response = await browserAutoSuggestion(
        objectToQueryString(apiJournalData)
      );

      setAutoSearch(response?.data.data.jsonfacets.resource_name_tk.buckets);
    }
    dispatch(setKeyword(e.target.value));
  };

  const onChangeKeywordPublisher = async (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setKeyWordPublisher(e.target.value);
    if (e.target.value.length >= 0) {
      // @ts-ignore
      let targetVal: string = e.target.value?.split(" ")?.join("\\ ");
      let apiData = {
        page: 1,
        rows: 15,
        profileuserid: searchContainOrStartWiths.login.user_id,
        fq__resource_type: `(${searchContainOrStartWiths.searchReducer.dataType
          .join(" OR ")
          .replace(/[()]/g, "")})`,
        publisher_name_tk: encodeURIComponent(`${targetVal}*`),
        fq__active_flag: true,
        facet_fields: "publisher_name",
        sort: "publisher_name_tk asc",
        op_mode: "and",
        fl: "publisher_name_tk, primary_publisher_country,publisher_id",
        group_field: "publisher_name_tk",
        group_ngroups: true,
      };
      let res = await publisherAutoSuggestion(objectToQueryString(apiData));
      setAutoSearchPublisher(
        res?.data?.data?.facets?.facet_fields?.publisher_name
      );
    }
  };

  const onChangeKeywordPackage = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setKeyWordPackage(e.target.value);

    // dispatch(setKeyword(e.target.value));
  };

  // FOR TITLE A-Z
  const updateSelectedTags = (element: any, index: any) => {
    let temp = Array(28).fill(false);
    dispatch(updateBrowserPage(1));
    logBasicSearchUsageData(86);

    if (selectedLetterTags[element]) {
      setSelectedLetterTags(temp);
      setSelectedTag("");
      dispatch(updateselectAlphabet(""));
    } else {
      if (element == 0) {
        temp[0] = true;
        setKeyWord("");
        setSelectedLetterTags(temp);
        setSelectedTag("");
        dispatch(
          setSearchKeyword({
            searchType: "",
            words: "",
          })
        );

        dispatch(updateselectAlphabet(""));
      } else if (element == 1) {
        temp[1] = true;
        setSelectedLetterTags(temp);
        dispatch(updateselectAlphabet("1"));
        setSelectedTag("");
      } else {
        temp[element] = true;
        setSelectedLetterTags(temp);
        setSelectedTag(String.fromCharCode(element + 63));
        dispatch(updateselectAlphabet(String.fromCharCode(element + 63)));
      }
    }
  };
  // FOR PUBLISHER
  const updateSelectedTagsPublisher = (element: any, index: any) => {
    logBasicSearchUsageData(86);

    let temp = Array(28).fill(false);
    dispatch(updateBrowserPagePublisher(1));
    if (selectedLetterTagsPublisher[element]) {
      setSelectedLetterTagsPublisher(temp);
      setSelectedTagPublisher("");
    } else {
      if (element == 0) {
        setKeyWordPublisher("");
        dispatch(setSearchKeywordPublisher([]));
        temp[0] = true;
        setSelectedLetterTagsPublisher(temp);
        setSelectedTagPublisher("");
        dispatch(updateselectAlphabetPublisher(""));
      } else if (element == 1) {
        temp[1] = true;
        setSelectedLetterTagsPublisher(temp);
        dispatch(updateselectAlphabetPublisher("1"));
        setSelectedTagPublisher("");
      } else {
        temp[element] = true;
        setSelectedLetterTagsPublisher(temp);
        setSelectedTagPublisher(String.fromCharCode(element + 63));
        dispatch(
          updateselectAlphabetPublisher(String.fromCharCode(element + 63))
        );
      }
    }
  };

  // FOR PACKAGE
  const updateSelectedTagsPackage = (element: any, index: any) => {
    let temp = Array(28).fill(false);
    logBasicSearchUsageData(86);
    if (selectedLetterTags[element]) {
      setSelectedLetterTagsPackage(temp);
      setSelectedTagPackage("");
    } else {
      if (element == 0) {
        temp[0] = true;
        setSelectedLetterTagsPackage(temp);
        setSelectedTagPackage("");
        dispatch(updateBrowserSearch_type(""));
        dispatch(updateBrowserSearch_term(""));

        setKeyWordPackage("");
      } else if (element == 1) {
        temp[1] = true;
        setSelectedLetterTagsPackage(temp);
        setSelectedTagPackage("");
      } else {
        temp[element] = true;
        setSelectedLetterTagsPackage(temp);
        setSelectedTagPackage(String.fromCharCode(element + 63));
        dispatch(updateBrowserSearch_type("1"));
        dispatch(updateBrowserSearch_term(String.fromCharCode(element + 63)));
      }
    }
  };

  const optionsList = [
    {
      label: "Starting with",
      value: "1",
    },
    {
      label: "Containing",
      value: "2",
    },
  ];

  const onChangeKeyword_Books = async (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setKeyWord(e.target.value);
    let searchedLetters: any = "";
    if (e.target.value.length >= 3) {
      // @ts-ignore
      let searchedArrayLetters = e.target.value.split(" ");
      searchedArrayLetters?.map((element: string) => {
        searchedLetters += `${element}\\ of\\ `;
      });

      let apiJournalData = {
        resource_name_tk: `${searchedLetters.slice(0, -6)}*`,
      };

      let response = await booksAutoSuggestion(
        objectToQueryString(apiJournalData)
      );

      setAutoSearch(response?.data.data.jsonfacets.resource_name_tk.buckets);
    }
    dispatch(setKeyword(e.target.value));
  };

  const triggerPreference = useRef(0);

  async function checkPreferenceAdded(myPreference: GetMyPreferenceData) {
    if (!myPreference) return;

    const filteredSelectedTags = myPreference?.DataTypes.filter(
      ({ is_favorite }: any) => is_favorite
    ).map(({ datatype_id }) => datatype_id);
    if (filteredSelectedTags?.filter((ele: any) => ele !== 2)?.length === 0) {
      setIsPreference(false);
      sessionStorage.setItem("preference", "false");
    }
  }

  function handlePreference(event: React.ChangeEvent<HTMLInputElement>) {
    logBasicSearchUsageData(112);
    if (isProfileUser) {
      let isMyPrefAdded: boolean = true;
      if (myPreference) {
        isMyPrefAdded = isPreferenceAddedSubjectAndDataType(myPreference);
      }

      if (!isMyPrefAdded && event.target.checked) {
        dispatch(
          setModal({
            modalType: "PREFERENCE",
            modalProps: {
              open: true,
              checkPreferenceAdded,
            },
          })
        );
      } else {
        setIsPreference(event.target.checked);
      }
      if (!event.target.checked) {
        dispatch(clearDataTypeFilters());
      }
      sessionStorage.setItem("preference", event.target.checked.toString());
      triggerPreference.current = triggerPreference.current + 1;
    } else {
      showIsProfileToast("Please login as profile user to use the preferences");
    }
  }

  async function applyPreference() {
    // if (!user_id || !customerData) return;
    if (!myPreference) return;

    dispatch(clearDataTypeFilters());

    if (isPreference && myPreference) {
      // Update preference data type
      const preferedSources = myPreference.DataTypes.filter(
        (type) => type.is_favorite
      )?.map((source) => source.datatype_id);

      if (preferedSources.includes(1)) {
        preferedSources.push(2);
        preferedSources.push(24);
        preferedSources.push(5);
      }

      // dispatch(updateDataType(preferedSources));

      // update subject ids
      const preferedSubjects = myPreference.Subjects?.map((l1) =>
        l1.subject2_details.filter((l2) => l2.is_favorite)
      )
        .flat()
        ?.map((l2) => {
          return {
            datsubjectmasterlevel1_id: -1, //Unused, Just for Integrity's Sake
            datsubjectmasterlevel2_id: l2.subject_id2,
            level2subject_name: l2.subject_name2,
          };
        });

      const subjectIds = preferedSubjects?.map(
        (x) => x.datsubjectmasterlevel2_id
      );

      dispatch(updateSubjectType(subjectIds));
      // dispatch(search());
    } else {
      const subjectIds = profileData1?.subject?.map(
        (x: any) => x?.datsubjectmasterlevel2_id
      );
      // const dataTypeIds = ["1", "2", "3", "4", "8", "24"];
      // // update datatype ids
      // dispatch(updateDataType(dataTypeIds));

      //Update Subject Ids
      dispatch(updateSubjectType(subjectIds));
      // dispatch(search());
    }
  }

  useEffect(() => {
    triggerPreference.current && applyPreference();
  }, [isPreference, myPreference]);

  function openPreferenceInfoModal() {
    dispatch(
      setModal({
        modalType: "PREFERENCE_INFO_MODEL",
        modalProps: {
          isOpen: true,
        },
      })
    );
  }
  return (
    <>
    <Box style={{display:"flex",justifyItems:"center"}}><Typography sx={webStyles.textResult} variant="h5">
        Journal Finder
      </Typography> 
      <Box sx={{ display: "flex", alignItems: "center" ,margin: "1% 3.5% 0% 0%",}}>
            <Typography sx={{    fontWeight: "500",
    marginLeft: "20%",}}>Preferences</Typography>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              marginLeft={"6%"}
            >
              <AntSwitch
                checked={isPreference}
                inputProps={{ "aria-label": "ant design" }}
                onChange={handlePreference}
              />
            </Stack>
            <Stack>
              <IconButton size="medium"
               onClick={openPreferenceInfoModal}
               >
                <InfoOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Stack>
          </Box></Box>  

      <TabView
        text={text}
        value={tabIndex}
        handleChange={handleChange}
      ></TabView>
      {tabIndex == 0 ? (
        <>
          <Box
            mr="3%"
            ml="3%"
            mt={"1%"}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <DropDownComponent items={year}></DropDownComponent>
            <Box style={{ flex: 1, alignSelf: "center" }}>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                options={autoSearch?.map((option: any) => option.val) || []}
                open={
                  isOpen &&
                  searchContainOrStartWith.toLowerCase() !== "containing"
                }
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                value={keyWord}
                sx={{ width: 300 }}
                // @ts-ignore
                onChange={(event: any, newValue: string) => {
                  setAutoSearch([]);
                  setKeyWord(newValue);
                  setKeyWords([
                    ...keyWords,
                    { searchType: searchContainOrStartWith, words: keyWord },
                  ]);
                  setIsOpen(false);
                }}
                // value={keyWord}
                size="small"
                // @ts-ignore
                style={{ width: "100%" }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && keyWord) {
                    logBasicSearchUsageData(88);
                    setKeyWords([
                      ...keyWords,
                      { searchType: searchContainOrStartWith, words: keyWord },
                    ]);
                    dispatch(
                      setSearchKeyword({
                        searchType: searchContainOrStartWith,
                        words: keyWord,
                      })
                    );
                    dispatch(updateselectAlphabet(""));

                    setHandleEnter(true);
                    setIsOpen(false);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    placeholder="Title"
                    onChange={onChangeKeyword}
                    sx={[
                      webStyles.textInputText,
                      { width: "100%", marginRight: "0.5%" },
                    ]}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      // type: "search",
                      endAdornment: (
                        <InputAdornment position="end">
                          {keyWord ? (
                            <IconButton
                              onClick={() => {
                                dispatch(setKeyword(""));
                                setKeyWord("");
                                dispatch(
                                  setSearchKeyword({
                                    searchType: searchContainOrStartWith,
                                    words: "",
                                  })
                                );
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          ) : null}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
            <Button
              variant="contained"
              sx={webStyles.button}
              onClick={async () => {
                let res;
                logBasicSearchUsageData(88);
                if (keyWord == "") {
                  setMessage({
                    messages: "Try for some appropriate keywords / terms",
                    open: true,
                  });
                  return;
                }

                clearAllFilters();
                dispatch(
                  setSearchKeyword({
                    searchType: searchContainOrStartWith,
                    words: keyWord,
                  })
                );
                setKeyWords([
                  ...keyWords,
                  { searchType: searchContainOrStartWith, words: keyWord },
                ]);

                if (tabIndex == 0) {
                  setatoz(true);
                }
                dispatch(updateselectAlphabet(""));
                dispatch(updateBrowserPage(1));
              }}
            >
              Search
            </Button>
          </Box>
          <Box ml={"3%"} mr={"3%"} mt={"1%"} display={"flex"}>
            {/* <AlphabeticalFilter
              selectedTags={selectedLetterTags}
              selectOnClick={updateSelectedTags}
            /> */}
            <AlphabeticalFilterForAuthor
              selectedTags={selectedLetterTags}
              selectOnClick={updateSelectedTags}
              enabledTags={enabledTags}
            />
          </Box>{" "}
        </>
      ) : tabIndex == 1 ? (
        <>
          <Box
            mr="3%"
            ml="3%"
            mt={"1%"}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <DropDownComponent items={year}></DropDownComponent>
            {/* <TextField
              onChange={onChangeKeywordPublisher}
              value={keyWordPublisher}
              size="small"
              placeholder="Search by Publisher Name"
              sx={[
                webStyles.textInputText,
                { alignSelf: "center", flex: 1, marginRight: "0.5%" },
              ]}
              onKeyPress={(e) => {
                if (e.key === "Enter" && keyWordPublisher) {
                  setKeyWordsPublisher([
                    ...keyWordsPublisher,
                    {
                      searchType: searchContainOrStartWith,
                      words: keyWordPublisher,
                    },
                  ]);
                  dispatch(
                    setSearchKeywordPublisher({
                      searchType: searchContainOrStartWith,
                      words: keyWordPublisher,
                    })
                  );
                  setHandleEnter(true);
                }
              }}
              InputProps={{
                // type: "search",
                endAdornment: (
                  <InputAdornment position="end">
                    {keyWordPublisher ? (
                      <IconButton
                        onClick={() => {
                          setKeyWordPublisher("");
                          dispatch(
                            setSearchKeywordPublisher({
                              searchType: searchContainOrStartWith,
                              words: "",
                            })
                          );
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    ) : null}
                  </InputAdornment>
                ),
              }}
            ></TextField> */}
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              options={autoSearchPublisher?.map(
                (option: any) => Object.keys(option) || []
              )}
              open={isOpenPublisher}
              onOpen={() => setIsOpenPublisher(true)}
              onClose={() => setIsOpenPublisher(false)}
              value={keyWordPublisher}
              // @ts-ignore
              onChange={(event: any, newVal: string) => {
                setAutoSearchPublisher([]);
                setKeyWordPublisher(newVal[0]);
                setKeyWordsPublisher([
                  ...keyWordsPublisher,
                  {
                    searchType: searchContainOrStartWith,
                    words: keyWordPublisher,
                  },
                ]);
              }}
              sx={{ width: 300 }}
              //  onChange={() => {}}
              size="small"
              // @ts-ignore
              style={{ width: "100%" }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && keyWordPublisher) {
                  setKeyWordsPublisher([
                    ...keyWordsPublisher,
                    {
                      searchType: searchContainOrStartWith,
                      words: keyWordPublisher,
                    },
                  ]);
                  dispatch(
                    setSearchKeywordPublisher({
                      searchType: searchContainOrStartWith,
                      words: keyWordPublisher,
                    })
                  );
                  setHandleEnter(true);
                }
              }}
              renderInput={(params) => (
                <TextField
                  placeholder="Search by Publisher Name"
                  onChange={onChangeKeywordPublisher}
                  sx={[
                    webStyles.textInputText,
                    { width: "100%", marginRight: "0.5%" },
                  ]}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        {keyWordPublisher ? (
                          <IconButton
                            onClick={() => {
                              setKeyWordPublisher("");
                              dispatch(
                                setSearchKeywordPublisher({
                                  searchType: searchContainOrStartWith,
                                  words: "",
                                })
                              );
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        ) : null}
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              )}
            />

            <Button
              variant="contained"
              sx={webStyles.button}
              onClick={async () => {
                let res;

                if (keyWordPublisher == "") {
                  setMessage({
                    messages: "Try for some appropriate keywords / terms",
                    open: true,
                  });
                  return;
                }
                clearAllFilters();

                setKeyWordsPublisher([
                  ...keyWordsPublisher,
                  {
                    searchType: searchContainOrStartWith,
                    words: keyWordPublisher,
                  },
                ]);
                dispatch(
                  setSearchKeywordPublisher({
                    searchType: searchContainOrStartWith,
                    words: keyWordPublisher,
                  })
                );

                setatozPublisher(true);
              }}
            >
              Search
            </Button>
          </Box>
          <Box ml={"3%"} mr={"3%"} mt={"1%"} display={"flex"}>
            <AlphabeticalFilter
              selectedTags={selectedLetterTagsPublisher}
              selectOnClick={updateSelectedTagsPublisher}
              enabledTags={enabledTags}
            />
          </Box>{" "}
        </>
      ) : tabIndex == 2 ? (
        <>
          <Box
            mr="3%"
            ml="3%"
            mt={"1%"}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <DropDownComponent items={year}></DropDownComponent>
            <TextField
              onChange={onChangeKeywordPackage}
              value={keyWordPackage}
              size="small"
              placeholder="Search by  Package Name"
              sx={[
                webStyles.textInputText,
                { alignSelf: "center", flex: 1, marginRight: "0.5%" },
              ]}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setKeyWordsPackage([
                    {
                      searchType: searchContainOrStartWith,
                      words: keyWordPackage,
                    },
                  ]);
                  dispatch(updateBrowserSearch_term(keyWordPackage));

                  setHandleEnter(true);
                }
              }}
            ></TextField>
            <Button
              variant="contained"
              sx={webStyles.button}
              onClick={async () => {
                let res;
                if (keyWordPackage == "") {
                  setMessage({
                    messages: "Try for some appropriate keywords / terms",
                    open: true,
                  });
                  return;
                }
                clearAllFilters();

                setKeyWordsPackage([
                  ...keyWords,
                  {
                    searchType: searchContainOrStartWith,
                    words: keyWordPackage,
                  },
                ]);
                dispatch(updateBrowserSearch_term(keyWordPackage));
                if (tabIndex == 2) {
                  setatozPackage(true);
                } else if (tabIndex == 1) {
                  // dispatch(setSearchKeyword(keyWord));
                  // setPublisher(true);
                }
              }}
            >
              Search
            </Button>
          </Box>
          <Box ml={"3%"} mr={"3%"} mt={"1%"} display={"flex"}>
            <AlphabeticalFilter
              selectedTags={selectedLetterTagsPackage}
              selectOnClick={updateSelectedTagsPackage}
            />
          </Box>{" "}
        </>
      ) : null}

      <Box sx={webStyles.mainContainer}>
        <Box ml={"3%"} mr={"3%"}>
          <Grid container mt={"2%"}>
            {tabIndex == 0 ? (
              <Grid item xs={3}>
                <Box sx={{ paddingBlock: 2 }}>
                  <Typography
                    fontWeight={"700"}
                    fontSize={"20px"}
                    lineHeight={"24px"}
                    style={webStyles.resulteText}
                  >
                    Results{" "}
                    {data?.hits ? data?.hits.toLocaleString("en-US") : ""}
                  </Typography>
                </Box>
                <CollectionsComponentOfBrowser
                  setMyPersonalLibrary={setMyPersonalLibrary}
                  setFilters={setFilters}
                  setCollectionFilter={setCollectionFilter}
                ></CollectionsComponentOfBrowser>

                <SearchFilterComponentOfBrowser
                  setFilters={setFilters}
                ></SearchFilterComponentOfBrowser>
              </Grid>
            ) : tabIndex === 1 ? (
              <Grid item xs={3}>
                <Box sx={{ paddingBlock: 2 }}>
                  <Typography
                    fontWeight={"700"}
                    fontSize={"20px"}
                    lineHeight={"24px"}
                    style={webStyles.resulteText}
                  >
                    Results{" "}
                    {publisherResults?.ngroups
                      ? publisherResults.ngroups.toLocaleString("en-US")
                      : ""}
                  </Typography>
                </Box>
                <CollectionsComponentOfPublisher
                  setMyPersonalLibraryPublisher={setMyPersonalLibraryPublisher}
                  setFilters={setFiltersPublisher}
                ></CollectionsComponentOfPublisher>

                <SearchFilterComponentOfPublisher
                  setFilters={setFiltersPublisher}
                ></SearchFilterComponentOfPublisher>
              </Grid>
            ) : tabIndex == 2 ? (
              <Grid item xs={12}>
                <Box sx={{ paddingBlock: 2 }}>
                  <Typography
                    fontWeight={"700"}
                    fontSize={"20px"}
                    lineHeight={"24px"}
                    style={webStyles.resulteText}
                  >
                    Results{" "}
                    {packageHits?.hits
                      ? packageHits?.hits.toLocaleString("en-US")
                      : ""}
                  </Typography>
                </Box>
              </Grid>
            ) : null}
            <Grid item xs={9}>
              {/* Header Div */}
              {tabIndex === 0 ? (
                <BrowseJournal
                  myPersonalLibrary={myPersonalLibrary}
                  handleEnter={handleEnter}
                  setHandleEnter={setHandleEnter}
                  selectedTag={selectedTag}
                  setSelectFilters={setSelectFilters}
                  filters={filters}
                  selectFilters={selectFilters}
                  setatoz={setatoz}
                  atoz={atoz}
                  keyWords={keyWords}
                  searchContainOrStartWith={searchContainOrStartWith}
                  setSelectedTag={setSelectedTag}
                  collectionFilter={collectionFilter}
                  setMyPersonalLibrary={setMyPersonalLibrary}
                ></BrowseJournal>
              ) : tabIndex === 1 ? (
                <Publisher
                  myPersonalLibraryPublisher={myPersonalLibraryPublisher}
                  keyWords={keyWordsPublisher}
                  filters={filtersPublisher}
                  selectedTagPublisher={selectedTagPublisher}
                  atozPublisher={atozPublisher}
                  searchContainOrStartWith={searchContainOrStartWith}
                  publisher={publisher}
                  setpublisher={setPublisher}
                  setFilters={setFiltersPublisher}
                  setMyPersonalLibraryPublisher={setMyPersonalLibraryPublisher}
                ></Publisher>
              ) : tabIndex === 2 ? (
                <Packages></Packages>
              ) : //   <LatestUpdates></LatestUpdates>
              null}
            </Grid>
          </Grid>

          <IconButton
            aria-label="Scroll to Top"
            sx={{
              position: "fixed",
              top: "63vh",
              right: "2vw",
              transform: "translateY(-50%)",
              border: "1px solid #D1D5DB",
              backgroundColor: "#FFF",
              color: "primary.main",
              "&:hover": {
                border: "1px solid #D1D5DB",
                backgroundColor: "#FFF",
                color: "primary.main",
                boxShadow: 2,
              },
            }}
            onClick={() => goToTop()}
          >
            <ArrowUpwardIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default BrowseJournalTableSearch;

const webStyles = {
  button: {
    margin: "0.5%",
    // height: 47,
    paddingLeft: "4%",
    paddingRight: "4%",
    borderRadius: 2,
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
  },
  mainContainer: {
    backgroundColor: "#f5f5f5",
    flex: 1,
  },
  headerText: {
    fontFamily: "Lora",
    color: Colors.primary,
  },
  subText: {
    fontFamily: "Helvetica Neue",
    fontWeight: 700,
    fontSize: "0.9rem",
  },
  checkBox: {
    fontWeight: 400,
    fontSize: "0.875rem",
    color: Colors.primary,
    fontFmaily: "Helvetica Neue",
  },
  textResult: {
    fontFamily: "Lora",
    fontWeight: "700",
    color: Colors.black,
    alignSelf: "center",
    backgroundColor: "green",
    margin: "1% 0% 0% 3.5%",
  },
  textInputText: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
  },
  resulteText: {
    fontFamily: "Helvetica Neue",
    fontsSyle: "normal",
    fontWeight: 700,
  },
};
