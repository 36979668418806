import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@mui/material";
import { Colors, dataTypes } from "../../utils/constants";
import NewIcon from "../../assets/img/New.png";
import { onSearch as search } from "../../screens/Search/onSearch";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  updataLibraryOpac,
  updateCollectionValue,
  updateConsortiaCollection,
  updateDSPACECollection,
  updateIsOpac_dspace,
  updateJgateCollection,
  updateMyLibraryCollection,
  updateOnosCollection,
  updatePersonalLibrary,
} from "../../store/slice/filterSlice";
import {
  updateDataType,
  updateDefaultDataType,
  updatePage,
} from "../../store/slice/searchSlice";
import { getQueryParams, showIsProfileToast } from "../../utils/helper";
import { personalCoreCount } from "../../api/Search/CollectionApi";
import { basicSearchForCounts } from "../../api/Search/BasicSearch.api";
import { updateQueries } from "../../store/slice/appliedQueries";
import { setSearch } from "../../api/Search/SetSearch";
import { queryConstructor } from "../../api/formQuery";
import { notify } from "../../utils/Notify";

//Type
interface PropType {
  jsonFacets?: any;
  data?: any;
  collectionsValue?: string;
  setcollectionsValue?: any;
  allCount?: number | string;
}

const CollectionsComponent = (props: PropType) => {
  let { jsonFacets, data, collectionsValue, setcollectionsValue, allCount } =
    props;
  const dispatch = useAppDispatch();
  const searchReducer = useAppSelector((state) => state.searchReducer);
  const filterReducer = useAppSelector((state) => state.filterReducer);
  const customerDetails = useAppSelector(
    (state) => state.customer.customerDetails
  );
  const consortiamaster_id = useAppSelector(
    (state) => state.customer.customerDetails?.consortiamaster_id
  );
  const [isProfileUser, setIsProfileUser] = useState(false);
  const dataTypeIds: any = useAppSelector(
    (state) => state.searchReducer.dataType
  );

  const personalDetails: any = useAppSelector(
    (state) => state.customer?.personalDetails
  );

  const isDSpace = filterReducer.dspace;
  const isEnableONOS = useAppSelector(
    (state) => state.customer.customerDetails?.is_enable_onos
  );

  const setSearchReducer = useAppSelector((state) => state.setSearch);
  const defaultDataTypes = useAppSelector(
    (state) => state.searchReducer.defaultDataType
  );

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const searchBoxSearchTerm: any = urlParams.get("q");
  const isSearchBox = sessionStorage.getItem("isSearchBox") === "true";
  const hasSeachBoxRef = useRef(false);

  let dataType = [];
  if (
    (dataTypeIds?.length === 1 && dataTypeIds?.includes(100)) ||
    (dataTypeIds?.length === 1 && dataTypeIds?.includes(101)) ||
    (dataTypeIds?.length === 2 &&
      dataTypeIds?.includes(100) &&
      dataTypeIds?.includes(101))
  ) {
    dataType = defaultDataTypes;
  } else if (dataTypeIds?.length) {
    dataType = dataTypeIds;
  } else {
    dataType = defaultDataTypes;
  }

  // useEffect(() => {
  //   if (dataType.length === 0) {
  //     dispatch(updateDataType(defaultDataTypes));
  //   }
  // }, [dataType]);

  // const dataTypeToString =
  //   Array.isArray(dataType) && dataType?.map((val) => val.toString());
  const dataTypeToString =
    Array.isArray(dataType) && dataType?.map((val) => String(val));
  const queryCount = useAppSelector((state) => state.queryForCount);

  const [count, setCount] = useState({
    jgateCollection: 0,
    personalLibCollection: 0,
    myLibrary: 0,
    consortiaSubscription: 0,
    onosCollection: 0,
  });

  useEffect(() => {
    if (
      !filterReducer.libraryOpac &&
      !filterReducer.myLibraryCollection &&
      !filterReducer.myPersonalLibCollection &&
      !filterReducer.jgateCollection &&
      !filterReducer.onosCollection &&
      !filterReducer.consortiaCollection &&
      !filterReducer.dspace
    ) {
      if (
        customerDetails?.product_type === "1" ||
        customerDetails?.product_type === "1,7"
      ) {
        dispatch(updateMyLibraryCollection(false));
        dispatch(updateConsortiaCollection(false));
        dispatch(updateOnosCollection(false));
        dispatch(updatePersonalLibrary(false));
        dispatch(updataLibraryOpac(false));
        dispatch(updateJgateCollection(true));
        dispatch(updateDSPACECollection(false));

        dispatch(
          updateQueries({
            key: "jgateCollection",
            value: true,
          })
        );
      } else if (customerDetails?.product_type === "7") {
        dispatch(updateMyLibraryCollection(false));
        dispatch(updateJgateCollection(false));
        dispatch(updateConsortiaCollection(true));
        dispatch(updateOnosCollection(false));
        dispatch(updatePersonalLibrary(false));
        dispatch(updataLibraryOpac(false));
        dispatch(updateDSPACECollection(false));

        dispatch(
          updateQueries({
            key: "consortiaSubscriptions",
            value: true,
          })
        );
      }
    }
  }, [customerDetails]);

  const handleChangeCollections = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(updatePage(1));
    if (event.target.value === "MyLibraryCollection") {
      setcollectionsValue((event.target as HTMLInputElement).value);
    } else if (
      event.target.value === "MyPersonalLibraryCollection" &&
      isProfileUser
    ) {
      setcollectionsValue((event.target as HTMLInputElement).value);
    } else if (
      event.target.value === "J-GateCollection" ||
      event.target.value === "ConsortiaSubscriptions" ||
      event.target.value === "ONOSCollections" ||
      event.target.value === "Library OPAC" ||
      event.target.value === "IR/DSPACE"
    ) {
      setcollectionsValue((event.target as HTMLInputElement).value);
    }
    dispatch(updateCollectionValue(event?.target?.value));

    if (event.target.value === "J-GateCollection") {
      dispatch(updateMyLibraryCollection(false));
      dispatch(updateConsortiaCollection(false));
      dispatch(updateOnosCollection(false));
      dispatch(updatePersonalLibrary(false));
      dispatch(updataLibraryOpac(false));
      dispatch(updateJgateCollection(true));
      dispatch(updateDSPACECollection(false));
      dispatch(updateIsOpac_dspace(false));

      dispatch(
        updateQueries({
          key: "jgateCollection",
          value: true,
        })
      );

      triggerEvent.current = triggerEvent.current + 1;
    } else if (event.target.value === "ConsortiaSubscriptions") {
      dispatch(updateMyLibraryCollection(false));
      dispatch(updateJgateCollection(false));
      dispatch(updateConsortiaCollection(true));
      dispatch(updateOnosCollection(false));
      dispatch(updatePersonalLibrary(false));
      dispatch(updataLibraryOpac(false));
      dispatch(updateDSPACECollection(false));
      dispatch(updateIsOpac_dspace(false));

      dispatch(
        updateQueries({
          key: "consortiaSubscriptions",
          value: true,
        })
      );

      triggerEvent.current = triggerEvent.current + 1;
    } else if (event.target.value === "MyLibraryCollection") {
      dispatch(updateJgateCollection(false));
      dispatch(updateConsortiaCollection(false));
      dispatch(updateMyLibraryCollection(true));
      dispatch(updateOnosCollection(false));
      dispatch(updatePersonalLibrary(false));
      dispatch(updataLibraryOpac(false));
      dispatch(updateDSPACECollection(false));
      dispatch(updateIsOpac_dspace(false));

      dispatch(
        updateQueries({
          key: "myLibraryCollection",
          value: true,
        })
      );

      triggerEvent.current = triggerEvent.current + 1;
    } else if (event.target.value === "ONOSCollections") {
      dispatch(updateMyLibraryCollection(false));
      dispatch(updateConsortiaCollection(false));
      dispatch(updatePersonalLibrary(false));
      //dispatch(updatePersonalLibrary(false));
      dispatch(updataLibraryOpac(false));
      dispatch(updateJgateCollection(false));
      dispatch(updateOnosCollection(true));
      dispatch(updateDSPACECollection(false));
      dispatch(updateIsOpac_dspace(false));

      dispatch(
        updateQueries({
          key: "onosCollection",
          value: true,
        })
      );

      triggerEvent.current = triggerEvent.current + 1;
    } else if (event.target.value === "Library OPAC") {
      fetchOpacData();
    } else if (event.target.value === "IR/DSPACE") {
      fetchDSPACEData();
    } else if (event.target.value === "MyPersonalLibraryCollection") {
      if (isProfileUser) {
        if (count.personalLibCollection === 0) {
          notify(
            "info",
            "Please visit Personal Library to configure My Favorites"
          );
        }
        dispatch(updateMyLibraryCollection(false));
        dispatch(updateConsortiaCollection(false));
        dispatch(updateOnosCollection(false));
        dispatch(updataLibraryOpac(false));
        dispatch(updatePersonalLibrary(true));
        dispatch(updateDSPACECollection(false));
        dispatch(updateIsOpac_dspace(false));

        dispatch(
          updateQueries({
            key: "myPersonalLibraryCollection",
            value: true,
          })
        );

        triggerEvent.current = triggerEvent.current + 1;
      } else {
        console.log("not profiel user");
        showIsProfileToast(
          "Please login as profile user to view this collection"
        );
      }
    }
  };

  async function fetchOpacData() {
    if (!customerDetails?.informaticscustomer_id) return;
    let searchTerm = searchReducer.searchTerm.toString();
    dispatch(updateMyLibraryCollection(false));
    dispatch(updateConsortiaCollection(false));
    dispatch(updateOnosCollection(false));
    dispatch(updatePersonalLibrary(false));
    dispatch(updataLibraryOpac(true));
    dispatch(updateDSPACECollection(false));
    //dispatch(updateIsOpac_dspace(false));

    dispatch(
      updateQueries({
        key: "opac",
        value: true,
      })
    );

    dispatch(search());
  }

  async function fetchDSPACEData() {
    if (!customerDetails?.informaticscustomer_id) return;
    let searchTerm = searchReducer.searchTerm.toString();
    dispatch(updateMyLibraryCollection(false));
    dispatch(updateConsortiaCollection(false));
    dispatch(updateOnosCollection(false));
    dispatch(updatePersonalLibrary(false));
    dispatch(updataLibraryOpac(false));
    dispatch(updateDSPACECollection(true));
    //dispatch(updateIsOpac_dspace(false));

    dispatch(
      updateQueries({
        key: "IR/DSPACE",
        value: true,
      })
    );

    dispatch(search());
  }

  const triggerEvent = useRef(0);

  useEffect(() => {
    if (triggerEvent.current) applyFilter();
  }, [triggerEvent.current]);

  useEffect(() => {
    // let userId = sessionStorage.getItem("isProfileUser");
    // if (userId === "true") {
    //   setIsProfileUser(true);
    // } else if (userId === "false") {
    //   setIsProfileUser(false);
    // }

    if (personalDetails?.user_type === 2) {
      setIsProfileUser(true);
    } else {
      setIsProfileUser(false);
    }
  }, [personalDetails]);

  useEffect(() => {
    updateCollectionCount();
  }, [queryCount.allTextQuery, queryCount.fullTextQuery]);

  useEffect(() => {
    updateSetSearchCollectionCount();
  }, [setSearchReducer.setSearchQuery]);

  async function applyFilter() {
    dispatch(search("", customerDetails, true));
  }

  useEffect(() => {
    if (isSearchBox && searchBoxSearchTerm && !hasSeachBoxRef.current) {
      dispatch(search("", null, true));
      sessionStorage.setItem("isSearchBox", "false");
      hasSeachBoxRef.current = true;
    }
  }, []);

  // display the collection based on selected document types
  const showMyLib =
    Array.isArray(dataTypeToString) &&
    (dataTypeToString.includes("1") ||
      dataTypeToString.includes("2") ||
      dataTypeToString.includes("3"));

  let libraryCatalogue = customerDetails?.other_integreted_product?.map(
    (val) => val.other_product_type
  );

  const showDSPACEFilter = libraryCatalogue?.includes("DSpace Integration");
  const showKohaFilter = libraryCatalogue?.includes("Koha");

  const showPersonalLib =
    Array.isArray(dataTypeToString) &&
    (dataTypeToString.includes("1") || dataTypeToString.includes("2")) &&
    isProfileUser;

  const showJgateCollection = customerDetails?.product_type?.includes("1");
  const showConsortiaSubscription =
    customerDetails?.product_type?.includes("7");

  async function updateCollectionCount() {
    try {
      // If set search enabled
      if (
        setSearchReducer.isSetSearchEnabled &&
        setSearchReducer.setSearchQuery
      ) {
        return;
      }

      let encodedString = encodeURIComponent(
        `${customerDetails?.informaticscustomer_id} OR ${customerDetails?.consortia_virtualid}`
      );
      let removeConsortiaParam = `&fq__acl_group=(${encodedString})`;

      let keywordToRemove = customerDetails?.informaticscustomer_id
        ? `&fq__acl_group=${customerDetails?.my_library_filter}`
        : "";

      let addingDataTypesforOpac =
        searchReducer?.dataType.includes(100) &&
        searchReducer?.dataType.length === 1
          ? `&fq__resource_type=(${searchReducer?.defaultDataType?.join(
              " OR "
            )})`
          : "";
      let addingDataTypesforDspace =
        searchReducer?.dataType.includes(101) &&
        searchReducer?.dataType.length === 1
          ? `&fq__resource_type=(${searchReducer?.defaultDataType?.join(
              " OR "
            )})`
          : "";

      let addingDataTypesforOpac_Dspace =
        searchReducer?.dataType.includes(100) &&
        searchReducer?.dataType.includes(101) &&
        searchReducer?.dataType.length === 2
          ? `&fq__resource_type=(${searchReducer?.defaultDataType?.join(
              " OR "
            )})`
          : "";

      const consortiaFilter =
        "&fq__acl_group=" + customerDetails?.consortia_filter;

      const removeMyLibraryCollectionFilter =
        "&fq__acl_group=" + customerDetails?.my_library_filter;
      const removedONOSFilter = "&fq__is_onos_resource=true";

      //const datTypes =customerDetails?.datatypes?.includes();

      const decodedQuery = decodeURIComponent(queryCount.allTextQuery);

      // let query = decodedQuery.replace(
      //   new RegExp("\\b" + keywordToRemove + "\\b", "gi"),
      //   ""
      // );
      // query = decodedQuery.replace(keywordToRemove, "");
      // query = decodedQuery.replace(removedONOSFilter, "");
      // query = decodedQuery.replace(removeMyLibraryCollectionFilter, "");
      // let finalQuery = query.replace(removeConsortiaParam, "");
      // finalQuery = finalQuery.replace(consortiaFilter, "");

      let query = decodedQuery
        .replace(new RegExp(`\\b${keywordToRemove}\\b`, "gi"), "") // remove keywordToRemove
        .replace(removedONOSFilter, "") // remove ONOS filter
        .replace(removeMyLibraryCollectionFilter, "") // remove my library filter
        .replace(removeConsortiaParam, "") // remove consortia param
        .replace(consortiaFilter, "") // remove consortia filter
        .replace("fq__resource_type=(100)", addingDataTypesforOpac)
        .replace("fq__resource_type=(101)", addingDataTypesforDspace)
        .replace(
          "fq__resource_type=(100 OR 101)",
          addingDataTypesforOpac_Dspace
        )
        .trim();

      const finalQuery = query.trim(); // Trim any extra spaces or unnecessary characters

      /**
       *  To get the count of J-gate collection
       */
      if (finalQuery) {
        let res = await basicSearchForCounts(finalQuery);
        let count = res?.hits;

        if (count) {
          setCount((prev) => ({
            ...prev,
            jgateCollection: count,
          }));
        }
        // let res = await basicSearchForCounts(finalQuery);
        // let count = res?.hits;

        const shouldCallOnSearch =
          sessionStorage.getItem("fromLoginModel") === "true";
        const isIPLogin = sessionStorage.getItem("isFirstIPLogin") === "true";
        // const isSearchBox = sessionStorage.getItem("isSearchBox") === "true";

        if (shouldCallOnSearch || isIPLogin) {
          dispatch(search("", null, true));
          sessionStorage.setItem("fromLoginModel", "false");
          sessionStorage.setItem("isFirstIPLogin", "false");
        }

        // if (count) {
        //   setCount((prev) => ({
        //     ...prev,
        //     jgateCollection: count,
        //   }));
      }

      /**
       *  To get the count of My Personal Library collection
       */
      const isProfileUser = sessionStorage.getItem("isProfileUser") === "true";
      if (isProfileUser) {
        let personalQuery = query.replace(
          "profileuserid=",
          "fq__profileuser_id="
        );
        personalQuery = personalQuery.replace(consortiaFilter, "");
        let finalQery = "";
        if (customerDetails?.product_type === "7") {
          finalQery = personalQuery + `&fq__acl_group=(${encodedString})`;
        } else {
          finalQery = personalQuery;
        }
        let personalCore = await personalCoreCount(finalQery);
        let personalCollectionCount = personalCore?.hits;
        if (personalCollectionCount) {
          setCount((prev) => ({
            ...prev,
            personalLibCollection: personalCollectionCount,
          }));
        } else {
          setCount((prev) => ({
            ...prev,
            personalLibCollection: 0,
          }));
        }
      }

      /**
       *  To get the count of My Library collection
       */
      // let myLibRes = await basicSearchForCounts(
      //   query +
      //     "&fq__acl_group=" +
      //     (customerDetails?.my_library_filter
      //       ? customerDetails?.my_library_filter
      //       : "")
      //    +
      // "&titleKeywordsAbs=" +
      // searchReducer?.searchTerm
      //);
      //console.log("query", query);

      let myLibRes = await basicSearchForCounts(
        query +
          "&fq__acl_group=" +
          (customerDetails?.my_library_filter
            ? customerDetails?.my_library_filter
            : "")
      );

      let myLibCount = myLibRes?.hits;

      if (myLibCount && customerDetails?.my_library_filter) {
        setCount((prev) => ({
          ...prev,
          myLibrary: myLibCount,
        }));
      } else {
        setCount((prev) => ({
          ...prev,
          myLibrary: 0,
        }));
      }

      /**
       *  To get the count of ONOS collection
       */
      if (isEnableONOS) {
        const onosQuery = query + "&fq__is_onos_resource=true";
        let onosCount = await basicSearchForCounts(onosQuery);
        setCount((prev) => ({
          ...prev,
          onosCollection: onosCount?.hits,
        }));
      } else {
        setCount((prev) => ({
          ...prev,
          onosCollection: 0,
        }));
      }

      /**
       *  To get the count of Consortia subscription collection
       */
      // if (consortiamaster_id && showMyLib) {
      if (consortiamaster_id && showConsortiaSubscription) {
        let consortiaParam = `&fq__acl_group=${customerDetails?.consortia_filter}`;
        let consortiaRes = await basicSearchForCounts(query + consortiaParam);
        let consortiaCount = consortiaRes?.hits;

        if (consortiaCount) {
          setCount((prev) => ({
            ...prev,
            consortiaSubscription: consortiaCount,
          }));
        } else {
          setCount((prev) => ({
            ...prev,
            consortiaSubscription: 0,
          }));
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function updateSetSearchCollectionCount() {
    if (
      !setSearchReducer.isSetSearchEnabled &&
      !setSearchReducer.setSearchQuery
    )
      return;
    /**
     *  To get the count of J-gate collection
     */
    let setSearchQuery = setSearchReducer.setSearchQuery;

    // ============> Removing the collection filter realted query from setSearchQuery =======================
    let queryObj = getQueryParams(setSearchQuery);
    delete queryObj.fq__acl_group;
    setSearchQuery = await queryConstructor(queryObj);
    // ============================= End =================================

    let res = await setSearch(setSearchQuery, "all", "count");
    let count = res?.hits;

    if (count) {
      setCount((prev) => ({
        ...prev,
        jgateCollection: count,
      }));
    }

    /**
     *  To get the count of My Personal Library collection
     */
    const isProfileUser = sessionStorage.getItem("isProfileUser") === "true";
    if (isProfileUser) {
      let personalCore = await setSearch(setSearchQuery, "myPersonal", "count");
      let personalCollectionCount = personalCore?.hits;
      if (personalCollectionCount) {
        setCount((prev) => ({
          ...prev,
          personalLibCollection: personalCollectionCount,
        }));
      } else {
        setCount((prev) => ({
          ...prev,
          personalLibCollection: 0,
        }));
      }
    }

    /**
     *  To get the count of My Library collection
     */
    const myLibRes = await setSearch(setSearchQuery, "myLibrary", "count");
    let myLibCount = myLibRes?.hits;

    if (myLibCount) {
      setCount((prev) => ({
        ...prev,
        myLibrary: myLibCount,
      }));
    } else {
      setCount((prev) => ({
        ...prev,
        myLibrary: 0,
      }));
    }

    /**
     *  To get the count of Consortia subscription collection
     */
    if (consortiamaster_id && showMyLib) {
      let consortiaRes = await setSearch(
        setSearchQuery,
        "myConsortia",
        "count"
      );
      let consortiaCount = consortiaRes?.hits;

      if (consortiaCount) {
        setCount((prev) => ({
          ...prev,
          consortiaSubscription: consortiaCount,
        }));
      } else {
        setCount((prev) => ({
          ...prev,
          consortiaSubscription: 0,
        }));
      }
    }
  }

  // If thesis doc type selected form the document type filter only show the jgate collection
  const isThesisDocTypeSelected =
    filterReducer.sourceType.length === 1 &&
    filterReducer.sourceType.includes(dataTypes.dissertationThesis);
  return (
    <>
      <Box sx={webStyle.leftBox}>
        <Box sx={{ paddingInline: "7%", paddingBlock: "4%" }}>
          <Typography sx={webStyle.titleHeader} gutterBottom>
            Collections
          </Typography>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={collectionsValue}
            onChange={handleChangeCollections}
          >
            {showJgateCollection && (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value="J-GateCollection"
                control={<Radio />}
                label={`J-Gate Collection ${
                  count?.jgateCollection
                    ? "(" + count?.jgateCollection + ")"
                    : ""
                }`}
              />
            )}
            {!isThesisDocTypeSelected && count?.myLibrary && showMyLib ? (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value="MyLibraryCollection"
                control={<Radio />}
                label={`${
                  customerDetails?.customershortname != null
                    ? customerDetails?.customershortname + " " + "Subscribed"
                    : "My Library Collection"
                } ${count.myLibrary ? "(" + count.myLibrary + ")" : ""}`}
              />
            ) : null}
            {showKohaFilter && (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value={"Library OPAC"}
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    Library Catalogue{" "}
                    <img src={NewIcon} style={{ width: "50px" }} />{" "}
                  </Box>
                }
              />
            )}
            {showDSPACEFilter && (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value={"IR/DSPACE"}
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    IR/DSPACE <img src={NewIcon} style={{ width: "50px" }} />{" "}
                  </Box>
                }
              />
            )}
            {!isThesisDocTypeSelected && showPersonalLib ? (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value="MyPersonalLibraryCollection"
                control={<Radio />}
                label={`My Personal Library Collection ${
                  count.personalLibCollection
                    ? "(" + count.personalLibCollection + ")"
                    : "(0)"
                } `}
              />
            ) : null}

            {!isThesisDocTypeSelected &&
            showConsortiaSubscription &&
            count.consortiaSubscription ? (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value="ConsortiaSubscriptions"
                control={<Radio />}
                label={`Consortia Subscriptions ${
                  count.consortiaSubscription
                    ? "(" + count.consortiaSubscription + ")"
                    : ""
                }`}
              />
            ) : null}
            {!isThesisDocTypeSelected && customerDetails?.is_enable_onos ? (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value="ONOSCollections"
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    ONOS Collections{" "}
                    {count?.onosCollection
                      ? "(" + count?.onosCollection + ")"
                      : ""}
                    {/* <img src={NewIcon} style={{ width: "50px" }} />{" "} */}
                  </Box>
                }
              />
            ) : null}
          </RadioGroup>
        </Box>
      </Box>
    </>
  );
};

export default CollectionsComponent;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftBox: {
    width: "92%",
    maxWidth: "340px",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
  },
  titleHeader: {
    fontFamily: "Lora",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "1.125rem",
  },
};
