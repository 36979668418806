import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import Pagination from "../../components/pagination";
import { Colors } from "../../utils/constants";

import Menu, { MenuProps } from "@mui/material/Menu";
import { alpha, styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getAlertDetails,
  getListOfJournal,
  journalLock,
} from "../../api/browseJournal";
import SortByRelevenceForBrowser from "../../components/basicSearchTopBar/sortByRelevenceBrowser";
import BrowserJournalBlocks from "../../components/browserJournalblocks/browserJournalblocks";
import {
  updateBrowserPage,
  updateBrowserRow,
} from "../../store/slice/browseFilterSlice";
import {
  alertsAndFav,
  browseJournalSearchResult,
  updateBrowseJournalSearch,
  updateJournalLockDisplay,
  updateknownAlphasearch,
} from "../../store/slice/browseJournal";
import { goToTop } from "../../utils/helper";
import objectToQueryString from "./objToQuery";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import NoResultModal from "../../components/modals/NoResultFound";
import SJRModal from "../../components/rightDrawer/SJRModal";
import Spinner from "../../components/spinner";
import { useAppSelector } from "../../store/hooks";
import { updateQueries } from "../../store/slice/appliedQueries";
import { updateIsAuthorResult } from "../../store/slice/authorFinderSlice";
import { clearAllFilters } from "../../store/slice/browseFilterSlice";
import {
  updataLibraryOpac,
  updateConsortiaCollection,
  updateJgateCollection,
  updateLoadingState,
  updateMyLibraryCollection,
  updateOnosCollection,
  updatePersonalLibrary,
} from "../../store/slice/filterSlice";
import { setModal } from "../../store/slice/modalSlice";
import { updateDataType } from "../../store/slice/searchSlice";
import { notify } from "../../utils/Notify";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const BrowseJournal = ({
  handleEnter,
  setHandleEnter,
  searchContainOrStartWith,
  // keyWords,
  atoz,
  setatoz,
  selectFilters,
  filters,
  setSelectFilters,
  selectedTag,
  myPersonalLibrary,
  collectionFilter,
}: any) => {
  const dispatch = useDispatch();
  let userId = sessionStorage.getItem("user_id");
  let session_key = sessionStorage.getItem("session_key");

  const [publicationValue, setPublicationValue] = useState("");
  const [collectionsValue, setcollectionsValue] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [userData, setUserData] = useState<any>({});
  const [alertSet, setAlertSet] = useState<any[]>([]);
  const userDatas = useAppSelector((state) => state.customer.customerDetails);

  const [journalName, setJournalName] = useState("");
  // const [selectedJournalList, setSelectedJournalList] = useState<any[]>([
  //   { searchType: searchType, keyword: resourceName },
  // ]);
  const [selectedListTags, setselectedListTags] = useState<any[]>([]);
  const [journalSearchResults, setJournalSearchResults] = useState<any[]>([]);
  const [AllNoOfResults, setAllNoOfResults] = useState(0);
  const [loading, setLoading] = useState(true);
  const [keywords, setKeywords] = useState<any[]>([]);
  const [renderFlag, setRenderFlag] = useState(true);
  const [facetFlag, setFacetFlag] = useState(false);
  const [facetFlag1, setFacetFlag1] = useState(false);
  const [facetFlag2, setFacetFlag2] = useState(false);
  // const [selectedRankSort, setSort] = useState("Journal");
  const isMounted = useRef(false);
  const [message, setMessage] = useState({ messages: "", open: false });
  const [noresultModal, setNoResultModal] = useState(false);

  const [sortBy, setSortBy] = useState(false);
  // const [sort, setSort] = useState("");

  const [jsonFacet, setJsonFacet] = useState<any>({});
  const [filterFlag, setFilterFlag] = useState(false);
  const [options, setOptions] = useState("1");

  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState("");
  const [selectedRanking, setSelectedRanking] = useState<string[]>([]);
  const [selectedSourceIndex, setSelectedSourceIndex] = useState<string[]>([]);
  const [selectedPublisherCountry, setSelectedPublisherCountry] = useState<
    string[]
  >([]);
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
  const [gridDisplay, setGridDisplay] = useState<boolean>(false);
  const [gridId, setGridId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [sjrIds, setSjrId] = useState("");

  let selectedLetterTags = Array(28).fill(false);
  const selectedRankSort = useAppSelector(
    (state) => state.browseFilterSlice.sort
  );
  const [selectedTags, setSelectedTags] =
    useState<boolean[]>(selectedLetterTags);

  const consortiamasterId = useSelector(
    (state: any) => state?.customer?.customerDetails?.consortiamaster_id
  );

  const [selectedTab, setSelectedTab] = useState("all");

  const [apipage, setApiPage] = useState(1);
  const independentFilter = useSelector(
    (state: any) => state.browseFilterSlice.currentUpdate
  );

  const searchReducer: any = useSelector((state: any) => state.searchReducer);
  const setAlphabet: any = useSelector(
    (state: any) => state.browseFilterSlice.selectAlphabet
  );

  const loginDetails: any = useSelector((state: any) => state.login);

  const rows = useSelector((state: any) => state.browseFilterSlice.rows);
  let currentPage = useSelector((state: any) => state.browseFilterSlice?.page);
  const customerId = useSelector(
    (state: any) => state?.customer?.customerDetails?.informaticscustomer_id
  );
  const consortia_virtualid = useAppSelector(
    (state) => state.customer.customerDetails?.consortia_virtualid
  );

  const profileStatus = sessionStorage.getItem("isProfileUser");

  const customerData = useAppSelector(
    (state) => state.customer.customerDetails
  );

  // useEffect(() => {
  //   showLoginModa();
  // }, [profileStatus]);

  useEffect(() => {
    showLoginModa();
  }, [customerData?.customer_id]);

  function showLoginModa() {
    if (!customerData?.allow_profile) return;
    if (sessionStorage.getItem("isLoginModalShowed") === "true") return;
    // if (profileStatus === "false" || customerData?.customer_id === 0) {
    if (customerData?.customer_id === 0) {
      dispatch(
        setModal({
          modalType: "SHOW_LOGIN",
          modalProps: {
            open: true,
            isProfileUser: profileStatus,
          },
        })
      );
      sessionStorage.setItem("isLoginModalShowed", "true");
    }
  }

  useEffect(() => {
    setUserData(searchReducer);
    return () => {
      // dispatch(updateCurrentUpdate(false));
    };
  }, []);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setMessage({
      messages: "",
      open: false,
    });
  };

  const searchResult = useSelector(
    (state: any) => state.browseJournalSearch.browserResult?.docs
  );
  const searchResults = useSelector(
    (state: any) => state.browseJournalSearch.browserResult
  );

  const filterReducer = useAppSelector((state) => state.filterReducer);

  const enabledTags = useSelector(
    (state: any) =>
      state.browseJournalSearch?.browserResult?.facets?.facet_fields
        ?.resource_name_initial
  );

  let count = Math.ceil(searchResults?.hits / rows);

  const alertsAndFavs = useSelector(
    (state: any) => state?.browseJournalSearch?.alertFav[0]
  );
  const isLoading = useAppSelector((state) => state.filterReducer.loading);

  const BrouseSubjectData = useSelector(
    (state: any) => state?.browserfilterSlice?.subject
  );

  function handlePageChange(p: any) {
    dispatch(updateBrowserPage(p));
    // setPage(p);
    isMounted.current = true;
    goToTop();
  }

  function getRowsPerPage(value: number) {
    isMounted.current = true;
    dispatch(updateBrowserRow(value));
    // setRow(value);
  }

  // function generatedFilterString() {
  //   // console.log("abc".replaceAll('&', '\\&'))

  //   // const collections = selectedDate === '' ? '' : '&fq__early_online=true';
  //   let publisherCountry;
  //   let source_index;
  //   let subjects;
  //   if (updateFilters?.countryOfPublication?.length !== 0) {
  //     publisherCountry =
  //       "&" +
  //       objectToQueryString({
  //         primary_publisher_country:
  //           updateFilters?.countryOfPublication?.join('" OR "'),
  //       });
  //   } else if (updateFilters?.sourceIndex?.length !== 0) {
  //     source_index =
  //       "&" +
  //       objectToQueryString({
  //         fq__resource_source_index:
  //           updateFilters?.sourceIndex?.length.join('" OR "'),
  //       });
  //   } else if (updateFilters?.subject?.length !== 0) {
  //     subjects =
  //       "&" +
  //       objectToQueryString({
  //         primary_publisher_country: updateFilters?.subject?.join('" OR "'),
  //       });
  //   }

  //   // @ts-ignore
  //   setFilters(publisherCountry + source_index + subjects);
  //   // return publisherCountry + source_index + subjects;
  // }
  // generatedFilterString();
  //   CONTENT DATA
  const keyWords = useSelector(
    (state: any) => state.browseJournalSearch.searchKeyword
  );

  const lock = useSelector(
    (state: any) => state?.browseJournalSearch?.journalLockDisplay?.data?.data
  );

  // subjects_name_l3
  const selectedFilters = useAppSelector((state) => state.browseFilterSlice);

  const l3Subjects: any = selectedFilters?.subject
    ?.filter((subject: any) => !subject.isSelected)
    .flatMap((subject: any) => subject.subjects_name_l3.buckets)
    .filter((bucket) => bucket.isChecked);

  const selected_l3_subjects = l3Subjects?.map((val: any) => val.val);

  const l2Subjects:any=selectedFilters?.subject?.filter((subject:any)=>subject.isSelected).map((l2subject:any)=>l2subject.val).join('" OR "')


  const setJournalResults = async () => {
    dispatch(updateLoadingState(true));

    let subjects = "";
    let dataTypes = "";
    if (searchReducer.subjectType?.length > 0) {
      searchReducer.subjectType?.map((element: any, index: any) => {
        subjects += `${element} OR `;
      });
      subjects = `(${subjects.slice(0, -3)})`;
    }

    if (searchReducer?.defaultDataType?.length > 0) {
      searchReducer?.defaultDataType?.map((element: any) => {
        dataTypes += `${element} OR `;
      });

      dataTypes = `(${dataTypes.slice(0, -3)})`;
    }
    let temp: String[] = [];
    let resourceList = "";

    keyWords?.map((element: any) => {
      let key = "";
      let strLen = element.words?.split(" ");
      element.words?.split(" ")?.map((ele: any, indexs: number) => {
        console.log("strLen.length", strLen.length, indexs);
        strLen.length - 1 !== indexs
          ? (key += ele?.replace(/^\"+|\“+|\"+|\”$/g, "") + "\\ ")
          : (key += ele);
      });
      if (element.searchType === "Starting With") {
        // resourceList +=
        //   key?.indexOf(":\\") > -1
        //     ? `fq__resource_name_tk=${key.split(":\\").join("\\:\\")}* AND `
        //     : `fq__resource_name_tk=${key}* AND `;

        resourceList +=
          key?.indexOf(":\\") > -1
            ? `fq__resource_name_tk=${
                key
                  .split(":\\")
                  .join("\\:\\")
                  .replace(/\(/g, "\\(") // Escapes all '(' as '\('
                  .replace(/\)/g, "\\)") // Escapes all ')' as '\)')
              }* AND `
            : `fq__resource_name_tk=${
                key
                  .replace(/\(/g, "\\(") // Escapes all '(' as '\('
                  .replace(/\)/g, "\\)") // Escapes all ')' as '\)')
              }* AND `;
      } else if (element.searchType == "Containing") {
        resourceList +=
          key?.indexOf(":") > -1
            ? `fq__resource_name_tk="*${key
                ?.substring(key?.indexOf(":") + 2)
                ?.split(" ")
                .join("\\ ")}"* AND`
            : `fq__resource_name_tk=*${key}* AND `;
      }
    });
    resourceList = `&${resourceList.slice(0, -5)}`;

    if (keyWords.length == 0) resourceList = "";
    setselectedListTags([...temp, ...keywords]);

    let apiJournalData: any = {
      page: currentPage,
      rows: rows,
      profileuserid: userId,
      sessionid: session_key,
      facet_fields:
        "publisher_name,primary_publisher_country,subjects_name_l3,resource_source_index,filter_metrix,resource_name_initial",

      fq__active_flag: true,
      fq__subjects_id_l2: subjects,
      json_facet: JSON.stringify({
        subjects_name_l2: {
          type: "terms",
          field: "subjects_name_l2",
          limit: 100,
          // sort: "index",
          facet: {
            subjects_name_l3: {
              type: "terms",
              field: "subjects_name_l3",
              // sort: "index",
              limit: 60,
            },
          },
        },
      }),
    };
    if (independentFilter)
      Object.assign(apiJournalData, {
        fq__dateofpublication: "[NOW/YEAR TO NOW/YEAR]",
        fq__createddate: "[NOW-60DAYS TO NOW]",
      });
    // if (subjects.length > 4)
    //   Object.assign(apiJournalData, { fq__subjects_id_l2: subjects });
    if (dataTypes.length > 4)
      Object.assign(apiJournalData, { fq__resource_type: dataTypes });
    if (keyWords[keyWords.length - 1]?.searchType === "Starting With") {
      Object.assign(apiJournalData, { sort: "resource_name_tk asc" });
    }
    // if (selectedRankSort == "Journal" || selectedRankSort == "") {
    //   Object.assign(apiJournalData, { sort: "resource_name_tk asc" });
    // }
    // if (selectedRankSort !== "" && selectedRankSort !== "Journal")
    //   Object.assign(apiJournalData, { sort: selectedRankSort });
    if (selectedRankSort == "" || selectedRankSort == "SJR") {
      Object.assign(apiJournalData, { sort: "sjr_score desc" });
    }

    if (selectedRankSort !== "" && selectedRankSort !== "SJR")
      Object.assign(apiJournalData, { sort: selectedRankSort });

    if (setAlphabet !== "" && setAlphabet !== "0-9")
      Object.assign(apiJournalData, { fq__resource_name_initial: setAlphabet });
    if (
      selectedFilters.collectionsValueJournal === "Open Access Collections" &&
      !filters.includes("&fq__fulltext_ACL")
    )
      Object.assign(apiJournalData, { fq__fulltext_ACL: "(0)" });
    if (
      selectedFilters.collectionsValueJournal === "My Library Collections" &&
      !filters.includes("&fq__fulltext_ACL")
    )
      Object.assign(apiJournalData, {
        fq__fulltext_ACL: `${userDatas?.my_library_filter}`,
      });
    if (
      selectedFilters.collectionsValueJournal === "Consortia Collections" &&
      !filters.includes("&fq__fulltext_ACL")
    )
      Object.assign(apiJournalData, {
        fq__fulltext_ACL: `${userDatas?.consortia_filter}`,
      });

    //for ONOS Collection
    if (
      selectedFilters.collectionsValueJournal === "ONOS Collections" &&
      !filters.includes("&fq__fulltext_ACL")
    )
      // Object.assign(apiJournalData, { fq__fulltext_ACL: true });
      Object.assign(apiJournalData, { fq__is_onos_resource: true });

    // if (
    //   selectedFilters.subject.length > 0 &&
    //   !filters.includes("subjects_name_l3")
    // )
    //   Object.assign(apiJournalData, {
    //     subjects_name_l3: encodeURIComponent(
    //       '("' + selectedFilters.subject.join('" OR "') + '")'
    //     ),
    //   });

    if (
      // selected_l2_subjects.length > 0 &&
      selected_l3_subjects.length > 0 &&
      !filters.includes("subjects_name_l3")
    )
      Object.assign(apiJournalData, {
        subjects_name_l3: encodeURIComponent(
          '("' +
            selected_l3_subjects.map((val: any) => val).join('" OR "') +
            '")'
        ),
      });

      if(l2Subjects && !filters.includes("fq__subjects_name_l2") && !filters.includes("fq__(subjects_name_l2")){
        Object.assign(apiJournalData,{
          fq__subjects_name_l2:encodeURIComponent(
            '("'+
            l2Subjects +'")'
          )
        })
      }

    if (
      selectedFilters.journalRank.length > 0 &&
      !filters.includes("filter_metrix")
    )
      Object.assign(apiJournalData, {
        filter_metrix: encodeURIComponent(
          '("' + selectedFilters.journalRank.join('" OR "') + '")'
        ),
      });

    if (
      selectedFilters.countryOfPublication.length > 0 &&
      !filters.includes("primary_publisher_country")
    )
      Object.assign(apiJournalData, {
        primary_publisher_country: encodeURIComponent(
          '("' + selectedFilters.countryOfPublication.join('" OR "') + '")'
        ),
      });
    if (
      selectedFilters.sourceType.length > 0 &&
      !filters.includes("fq__resource_source_index")
    )
      Object.assign(apiJournalData, {
        fq__resource_source_index: encodeURIComponent(
          '("' + selectedFilters.sourceType.join('" OR "') + '")'
        ),
      });

    // if the user only has the consortia access the consortia_filter query should append in all query
    const isOnlyConsortiaAccess = customerData?.product_type === "7";
    if (isOnlyConsortiaAccess) {
      apiJournalData.fq__acl_group = customerData.consortia_filter;
    }

    let apiResults: any[] = [...journalSearchResults];
    if (resourceList.length <= 3) resourceList = "";
    let response;
    if (
      myPersonalLibrary ||
      selectedFilters?.collectionsValueJournal === "MyPersonalLibraryCollection"
    ) {
      Object.assign(apiJournalData, {
        fq__profileuser_id: userId,
        fq__is_favourite: true,
      });
      response = await getListOfJournal(
        "personalresourcecore?" +
          objectToQueryString(apiJournalData) +
          resourceList +
          filters
      );

      if (response?.data?.data?.hits === 0) {
        notify(
          "info",
          "Please visit Personal Library to configure My Favorites"
        );
      }
    } else if (
      collectionFilter === "My Library Collections" ||
      collectionFilter === "Consortia Collections" ||
      selectedFilters.collectionsValueJournal === "My Library Collections" ||
      selectedFilters.collectionsValueJournal === "Consortia Collections"
    ) {
      Object.defineProperty(apiJournalData, "fq__subjects_id_l2", {
        configurable: true,
      });
      delete apiJournalData.fq__subjects_id_l2;
      response = await getListOfJournal(
        "resourcecore?" +
          objectToQueryString(apiJournalData) +
          resourceList +
          filters
      );
    } else {
      response = await getListOfJournal(
        "resourcecore?" +
          objectToQueryString(apiJournalData) +
          resourceList +
          filters
      );
    }
    if (
      filters.includes("&fq__fulltext_ACL=") &&
      response?.data.data.docs.length === 0
    ) {
      dispatch(updateLoadingState(false));

      setMessage({
        messages:
          "There are no configured subjects nor subscription information for your library . Please contact your library administrator.",
        open: true,
      });
      return;
    }
    dispatch(browseJournalSearchResult(response?.data.data));
    if (response?.data?.data?.hits === 0) {
      setNoResultModal(true);
    }

    let enabledTagsList: any[] = ["ALL", "(0-9)"];
    if (setAlphabet === "") {
      response?.data.data.facets.facet_fields?.resource_name_initial?.map(
        (ele: any) =>
          Object.keys(ele)[0]?.charAt(0)?.toUpperCase().match(/[a-z]/i)
            ? enabledTagsList.push(
                Object.keys(ele)[0]?.charAt(0)?.toUpperCase()
              )
            : null
      );
      dispatch(updateknownAlphasearch(enabledTagsList));
    }

    if (response?.data.data.docs.length == 0) setRenderFlag(false);
    // setApiPage(apipage + 1);
    setJsonFacet(response?.data.data.facets.facet_fields);
    apiResults = [...response?.data.data.docs];
    setAllNoOfResults(response?.data.data.hits);
    let apiJson: any = {
      usermaster_id: loginDetails.user_id || userId,
      resourcemaster_ids: [],
    };
    if (response?.data.data.docs.length > 0) {
      response?.data.data.docs?.map((element: any, index: any) => {
        apiJson.resourcemaster_ids = apiJson.resourcemaster_ids.concat(
          element.resourcemaster_id
        );
      });
    }

    apiJson.resourcemaster_ids = apiJson.resourcemaster_ids.toString();
    let alertRes = await getAlertDetails("?" + objectToQueryString(apiJson));

    dispatch(alertsAndFav(alertRes?.data.data[0]));

    // Lock Journal
    let apiLock: any = {
      customer_id:
        customerId || sessionStorage.getItem("informaticscustomer_id"),
      resource_id: [],
      access_type: 1,
    };
    if (consortiamasterId !== null && consortiamasterId !== undefined)
      Object.assign(apiLock, { consortia_id: consortiamasterId });
    // consortiamasterId
    if (response?.data.data.docs.length > 0) {
      response?.data.data.docs?.map((element: any, index: any) => {
        apiLock.resource_id = apiLock.resource_id.concat(
          element.resourcemaster_id
        );
      });
    }
    apiLock.resource_id = apiLock.resource_id.toString();

    if (selectedFilters?.collectionsValueJournal === "My Library Collections") {
      Object.assign(apiLock, { context_id: 2 });
    }

    if (selectedFilters?.collectionsValueJournal === "Consortia Collections") {
      Object.assign(apiLock, { context_id: 3 });
    }

    if (selectedFilters?.collectionsValueJournal === "ONOS Collections") {
      Object.assign(apiLock, { context_id: 5 });
    }

    let journalLockRes = await journalLock("?" + objectToQueryString(apiLock));
    dispatch(updateJournalLockDisplay(journalLockRes));
    dispatch(updateLoadingState(false));
  };

  async function closeNoResultModal() {
    dispatch(clearAllFilters());
    setNoResultModal(false);
  }

  useEffect(() => {
    setJournalResults();
  }, [
    filters,
    currentPage,
    setAlphabet,
    keyWords,
    keywords,
    selectedTab,
    myPersonalLibrary,
    facetFlag,
    selectedRankSort,
    facetFlag1,
    facetFlag2,
    rows,
    independentFilter,
    selectedFilters.collectionsValueJournal,
    searchReducer.subjectType
  ]);

  useEffect(() => {
    dispatch(updateDataType(searchReducer?.defaultDataType));
    dispatch(updateIsAuthorResult(false));
    dispatch(updateBrowseJournalSearch(true));
  }, []);

  useEffect(() => {
    if (
      !filterReducer.libraryOpac &&
      !filterReducer.myLibraryCollection &&
      !filterReducer.myPersonalLibCollection &&
      !filterReducer.jgateCollection &&
      !filterReducer.onosCollection &&
      !filterReducer.consortiaCollection
    ) {
      if (
        userDatas?.product_type === "1" ||
        userDatas?.product_type === "1,7"
      ) {
        dispatch(updateMyLibraryCollection(false));
        dispatch(updateConsortiaCollection(false));
        dispatch(updateOnosCollection(false));
        dispatch(updatePersonalLibrary(false));
        dispatch(updataLibraryOpac(false));
        dispatch(updateJgateCollection(true));

        dispatch(
          updateQueries({
            key: "jgateCollection",
            value: true,
          })
        );
      } else if (userDatas?.product_type === "7") {
        dispatch(updateMyLibraryCollection(false));
        dispatch(updateJgateCollection(false));
        dispatch(updateConsortiaCollection(true));
        dispatch(updateOnosCollection(false));
        dispatch(updatePersonalLibrary(false));
        dispatch(updataLibraryOpac(false));

        dispatch(
          updateQueries({
            key: "consortiaSubscriptions",
            value: true,
          })
        );
      }
    }
  }, [userDatas]);

  useEffect(() => {
    if (handleEnter) {
      setJournalResults();
    }
    setHandleEnter(false);
  }, [handleEnter]);

  // useEffect(() => {
  //   setatoz(false);
  // }, [selectedTag]);

  useEffect(() => {
    setRenderFlag(true);
  }, [keyWords, keywords, selectedTab, myPersonalLibrary]);

  useEffect(() => {
    setApiPage(1);
    setJournalSearchResults([]);
    setFacetFlag1(true);
  }, [selectedRankSort]);

  useEffect(() => {
    setRenderFlag(true);
  }, [facetFlag1]);

  useEffect(() => {
    setApiPage(1);
    setJournalSearchResults([]);
    setFacetFlag2(!facetFlag2);
  }, [selectedTags]);

  useEffect(() => {
    setRenderFlag(true);
    return () => {
      // dispatch(updateSort(""));
    };
  }, [facetFlag2]);
  const clearFilters = () => {
    setselectedListTags([]);

    setselectedListTags([]);
    setJournalSearchResults([]);
    setKeywords([]);
    setApiPage(1);
  };
  function closeSjrModal() {
    setIsOpen(false);
  }

  function getAccessLockWithResourceId(resourceMasterId: string) {
    if (!Array.isArray(lock)) return;
    let obj = lock.find((data) => data.resource_id == resourceMasterId);
    return obj;
  }

  console.log("searchResult", searchResult);

  return (
    <Box>
      <Box bgcolor={"#f5f5f5"}>
        {/* Header Div */}
        <Box
          sx={[
            webStyle.flexBox,
            { marginTop: "1%", justifyContent: "flex-end" },
          ]}
        >
          <SortByRelevenceForBrowser
            selectedRankSort={selectedRankSort}
            sortData={searchResults?.facets?.facet_fields?.filter_metrix}
          ></SortByRelevenceForBrowser>
        </Box>
        <Grid container>
          <Grid item xs={12}>
            {searchResult &&
              searchResult?.map((item: any, i: number) => {
                let lockData = getAccessLockWithResourceId(
                  item?.resourcemaster_id
                );
                return (
                  <BrowserJournalBlocks
                    setJournalResults={setJournalResults}
                    index={i}
                    journalSearchResults={journalSearchResults}
                    setJournalSearchResults={setJournalSearchResults}
                    item={item}
                    alerts={alertsAndFavs?.[i]}
                    loginDetails={loginDetails}
                    setMessage={setMessage}
                    lock={lockData}
                    gridDisplay={gridDisplay}
                    setGridDisplay={setGridDisplay}
                    gridId={gridId}
                    setGridId={setGridId}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    closeSjrModal={closeSjrModal}
                    setSjrId={setSjrId}
                  ></BrowserJournalBlocks>
                );
              })}
          </Grid>
        </Grid>

        {/* <UsePagination></UsePagination> */}
        {/* <PaginationRounded></PaginationRounded> */}
        <Box my={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Pagination
            count={count}
            handlePageChange={handlePageChange}
            rowsPerResult={rows}
            getRowsPerPage={getRowsPerPage}
            currentPage={currentPage}
          />
          {/* <Spinner isLoading={isLoading} /> */}
        </Box>
      </Box>

      <Snackbar
        open={message.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert severity="warning" sx={{ width: "100%" }}>
          {message.messages}
        </Alert>
      </Snackbar>
      <SJRModal isOpen={isOpen} handleClose={closeSjrModal} sjrId={sjrIds} />
      <NoResultModal isOpen={noresultModal} handleClose={closeNoResultModal} />
      <Spinner isLoading={isLoading} />
    </Box>
  );
};

export default BrowseJournal;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftBox: {
    width: "90%",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    marginTop: "2%",
    paddingLeft: "3%",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  textResult: {
    fontFamily: "Lora",
    fontWeight: "700",
    fontSize: 24,
    color: Colors.black,
  },
  button: {
    margin: "0.5%",
    paddingLeft: "3%",
    paddingRight: "3%",
  },
};
