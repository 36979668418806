import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    TextField,
    Typography,
    IconButton,
    Button
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
    handleSave: () => void;
    headerText: string;
    handleAlertTopicChange: (e: string) => void;
    onClose: () => void;
    open: boolean;
    alertTopic?: string
}

export default function SaveSearchModal(props: Props) {
    const { handleSave, handleAlertTopicChange, onClose, open, headerText, alertTopic } = props;
    return (
        <Dialog open={open} onClose={onClose} id={"search-history-save-modal"}>
            <DialogTitle
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    minWidth: "400px"
                }}
            >
                <Typography
                    textAlign="left"
                    fontFamily={"Lora"}
                    fontWeight={700}
                    fontSize={28}
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                >
                    {headerText}
                </Typography>

                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Box>
                    <Typography
                        fontSize={16}
                        fontWeight={500}
                        textAlign="left"
                        color="#212121"
                    >
                        Save Topic name
                    </Typography>

                    <TextField
                        margin="normal"
                        id="text"
                        placeholder="Enter topic name"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        value={alertTopic}
                        onChange={(e: any) => handleAlertTopicChange(e.target.value)}
                        sx={{ width: "100%", background: "#fffff" }}
                        fullWidth={true}
                    />
                </Box>
            </DialogContent>

            <DialogActions style={{ justifyContent: "flex-start", marginInline: "15px" }}>
            <Button
              sx={{
                "&:hover": {
                  backgroundColor: "#7852FB",
                },

                textAlign: "left",
                backgroundColor: "#7852FB",
                color: " #FAF8F5",
                textTransform: "capitalize",
                borderRadius: "8px",
                fontSize: "16px",
                width: "120px",
              }}
              onClick={handleSave}
            >
              Save
            </Button>

            <Button
              sx={{
                textAlign: "left",
                left: "42px",
                fontWeight: 500,
                fontSize: "18px",
                backgroundColor: "#FFFFFF",
                color: " #111928",
                textTransform: "capitalize",
                borderRadius: "8px",
                width: "120px",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
    )
}