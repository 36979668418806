import { createSlice } from "@reduxjs/toolkit";
import { stat } from "fs";

export interface InitialstateType {
  searchResult: any;
  searchTerm: string[];
  page: number;
  rows: number;
  dataType: number[];
  subjectType: number[];
  subjectName: string[];
  markedResult: any;
  markedArticleId: string[];
  fullText: boolean;
  newSearchStatus: boolean;
  authorName: string[];
}

const initialState: InitialstateType = {
  searchResult: {},
  searchTerm: [],
  page: 1,
  rows: 15,
  dataType: [],
  subjectType: [],
  subjectName: ["All"],
  markedResult: [],
  markedArticleId: [],
  fullText: true,
  newSearchStatus: false,
  authorName: [],
};

export const searchSlice = createSlice({
  name: "searchResultBrowseJournal",
  initialState,
  reducers: {
    updateSearchResults(state, { payload }) {
      state.searchResult = payload;
    },
    updateSearchTerm(state, { payload }) {
      if (state.searchTerm.includes(payload)) {
        state.searchTerm = state.searchTerm?.filter((data) => data !== payload);
      }

      if (state.searchTerm.length) {
        state.searchTerm = [...state.searchTerm, payload];
      } else {
        state.searchTerm = [payload];
      }
    },
    clearSearchTerm(state) {
      state.searchTerm = [];
    },
    sliceSearchTerm(state) {
      state.searchTerm.splice(1);
    },
    removeSearchTerm(state, { payload }) {
      state.searchTerm = state.searchTerm.filter((data) => data !== payload);
    },
    updatePage(state, { payload }) {
      state.page = payload;
    },
    updateRow(state, { payload }) {
      state.rows = payload;
    },
    updateDataType(state, { payload }) {
      state.dataType = payload;
    },
    updateSubjectType(state, { payload }) {
      state.subjectType = payload;
    },
    updateSubjectName(state, { payload }) {
      if (Array.isArray(payload) && !payload.length) {
        state.subjectName = ["All"];
      } else {
        state.subjectName = payload;
      }
    },
    updateMarkedResult(state, { payload }) {
      state.markedResult = payload;
    },
    updateMarkedArticleId(state, { payload }) {
      state.markedArticleId = payload;
    },
    clearMarkedResult(state) {
      state.markedArticleId = [];
      state.markedResult = [];
      state.authorName = [];
    },
    updateFullText(state, { payload }) {
      state.fullText = payload;
    },
    clearDataTypeFilters(state) {
      state.dataType = [];
      state.subjectType = [];
      state.subjectName = [];
      state.authorName = [];
    },
    updateNewSearchStatus(state, { payload }) {
      state.newSearchStatus = payload;
    },
    updateAuthorName(state, { payload }) {
      state.authorName = payload;
    },
  },
});

export const {
  updateSearchResults,
  updateSearchTerm,
  clearSearchTerm,
  removeSearchTerm,
  updatePage,
  updateRow,
  updateDataType,
  updateSubjectType,
  updateSubjectName,
  updateMarkedResult,
  updateMarkedArticleId,
  clearMarkedResult,
  updateFullText,
  sliceSearchTerm,
  clearDataTypeFilters,
  updateNewSearchStatus,
  updateAuthorName,
} = searchSlice.actions;
export default searchSlice.reducer;
