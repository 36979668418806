import {
  BarChart,
  Bar as ReBar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ReTooltip,
  Legend as ReLegend,
  Label as ReLabel,
  ResponsiveContainer,
} from "recharts";
import Box from "@mui/material/Box";
import { FC, useEffect, useState } from "react";
import { CategoricalChartState as BarType } from "recharts/types/chart/generateCategoricalChart";
import { onSearch as search } from "../../screens/Search/onSearch";
import { useAppDispatch } from "../../store/hooks";
import { updateAllFilter, updateYearFrom } from "../../store/slice/filterSlice";
import { hideModal } from "../../store/slice/modalSlice";
import { onAuthorResult as authorsearch } from "../../screens/AuthorFinder/onAuthorResult";
import { useSelector } from "react-redux";
import { AuthorFinderObj } from "../../types/interface";
import {
  updateAuthorPage,
  updateAuthorRow,
} from "../../store/slice/authorFinderSlice";
import { RectangleProps } from "recharts";
declare type RectRadius = [number, number, number, number];

type Facet = { val: any; Count: number };
type FacetProps = { val: any; count: number };

// Guard Function
function isRadiusArray(value: any): value is number[] {
  return Array.isArray(value) && value.length === 4;
}
const CustomBarShape: FC<RectangleProps> = (props) => {
  const {
    x,
    y,
    width,
    height,
    fill = "#7852FB",
    radius,
    cursor = "pointer",
  } = props;

  // Default values for rounded corners, will be overridden if radius is correct
  let rxTop = 4;
  let ryTop = 4;
  let rxBottom = 0;
  let ryBottom = 0;

  if (isRadiusArray(radius)) {
    [rxTop, ryTop, rxBottom, ryBottom] = radius;
  }

  // Ensure a minimum height of 1 pixel for the bar, if height is not zero
  const finalHeight = height && height > 0 ? Math.max(height, 1) : height;

  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={finalHeight}
      fill={fill}
      rx={rxTop} // horizontal radius for rounded corners at the top
      ry={ryTop} // vertical radius for rounded corners at the top
      cursor={cursor}
    />
  );
};

const AnalyticsBarChart = ({
  chartData,
  count,
}: {
  chartData: FacetProps[];
  count: number;
}) => {
  const dispatch = useAppDispatch();

  const [barData, setBarData] = useState<Facet[] | null>(null);
  const [isNoData, setIsNoData] = useState<boolean>(false);

  const authorReducer: any = useSelector(
    (state: AuthorFinderObj) => state.authorReducer
  );

  useEffect(() => {
    if (chartData?.length > 0) {
      const currentCountArr = chartData
        .slice(0, count)
        ?.map((item) => {
          const { count, ...rest } = item;
          return { Count: count, ...rest };
        })
        .reverse();
      setBarData(currentCountArr);
      setIsNoData(false);
    } else {
      setIsNoData(true);
    }
  }, [chartData, count]);

  const handleSelectedValue = ({ activeLabel: year }: BarType) => {
    dispatch(updateYearFrom([year]));

    authorReducer.isAuthorAnalytics
      ? dispatch(updateAuthorPage(1)) &&
        dispatch(updateAuthorRow(15)) &&
        dispatch(authorsearch("", null, true))
      : dispatch(search("", null, true));
    dispatch(
      updateAllFilter({
        key: "yearFrom",
        value: year?.toString(),
      })
    );

    dispatch(hideModal());
  };

  return (
    <>
      {isNoData ? (
        <Box
          sx={{
            height: "63vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 1,
            fontSize: "1.4rem",
          }}
        >
          No Data Available
        </Box>
      ) : (
        <Box
          sx={{
            height: "63vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflowX: "auto",
          }}
        >
          {barData && (
            <ResponsiveContainer width="100%" height="90%">
              <BarChart
                width={1000}
                height={490}
                data={barData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                onClick={handleSelectedValue}
              >
                <CartesianGrid vertical={false} horizontal={false} />
                <XAxis dataKey="val" axisLine={false} tickLine={false}>
                  <ReLabel
                    value="Year"
                    offset={-15}
                    position="insideBottom"
                    style={{
                      textAnchor: "middle",
                      color: "#111928",
                      fontWeight: "500",
                      fontFamily: "Helvetica Neue",
                      fontSize: "1em",
                      lineHeight: "150%",
                    }}
                  />
                </XAxis>
                <YAxis dataKey="Count" axisLine={false} tickLine={false}>
                  <ReLabel
                    value="Document Count"
                    angle={-90}
                    offset={-8}
                    position="insideLeft"
                    style={{
                      textAnchor: "middle",
                      color: "#111928",
                      fontWeight: "500",
                      fontFamily: "Helvetica Neue",
                      fontSize: "1em",
                      lineHeight: "150%",
                    }}
                  />
                </YAxis>
                <ReTooltip
                  cursor={{ fill: "transparent" }}
                  formatter={(value: any) => value?.toLocaleString("en-US")}
                />
                <ReLegend />
                {/* <ReBar
                  dataKey="Count"
                  fill="#7852FB"
                  barSize={21}
                  radius={[4, 4, 0, 0]}
                  background={{ fill: "#eee" }}
                /> */}
                <ReBar
                  dataKey="Count"
                  fill="#7852FB"
                  barSize={21}
                  background={{ fill: "#eee" }}
                  shape={<CustomBarShape />} // Use custom shape here
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </Box>
      )}
    </>
  );
};

export default AnalyticsBarChart;
