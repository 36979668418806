import React, { useState } from "react";
import {
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  Checkbox,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import logo from "../assets/img/logo.png";
import Paper from "@mui/material/Paper";
import Box, { BoxProps } from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../components/CommonButton";
import "../styles/SignUp.css";
import { RadioGroup } from "@mui/material";
import { Colors } from "../utils/constants";
import { userSignup } from "../api/userDetailsapi";
import { signupSuccess, signupFail } from "../store/slice/userSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllUserTypes } from "../api/userTypeApi";
import SignUpImage from "../assets/images/SignUp.png";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { useAppSelector } from "../store/hooks";
import getCustomerDetailsByIp from "../api/getCustomerDetailByIp";
import { setModal } from "../store/slice/modalSlice";
import { loginSuccess } from "../store/slice/loginSlice";
import { notify } from "../utils/Notify";
import { usagelogApi } from "../api/usageReportApi";
import ReactivateProfile from "../components/modals/ReactivateProfile.modal";
import http from "../api/httpService";
import { AxiosResponse } from "axios";
import { GetUserProfileRoot } from "../types/GetUserProfile.types";
import { userLogin } from "../api/userApi";
import { clearDataTypeFilters, reset } from "../store/slice/searchSlice";
import { clearCustomerDetails } from "../store/slice/customerDetails";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const phoneRegex = /^[0-9]{10,10}$/;
var letters = /^[A-Za-z]+$/;
const passwordRegex =
  /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{6,}$/;
const minLengthCheck = /^.{6,}$/;
const lowerCaseCheck = /^(?=.*[a-z])/;
const upperCaseCheck = /^(?=.*[A-Z])/;
const specialCharacterCheck = /^(?=.*[\W_])/;
const numberCheck = /^(?=.*\d)/;
const whitespaceCheck = /^(?!.*\s)/;

export interface State extends SnackbarOrigin {
  open: boolean;
}

function Item(props: BoxProps) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 0,
        m: 1,
        width: 450,
        textAlign: "center",
        ...sx,
      }}
      {...other}
    />
  );
}

const SignUp = () => {
  const [utype, setUtype] = useState([
    {
      label: "Student",
      value: "1",
    },
  ]);
  const [otherfield, setOtherField] = React.useState("");
  const [inputBox, setInputBox] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [authorField, setAuthorField] = useState(false);
  const [libraryIDField, setLibraryIDField] = useState(true);
  const [institutionalIDField, setInstitutionalIDField] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [emailid, setEmailid] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("CSI");
  const [instituteidnumber, setInstituteidnumber] = useState("");
  const [libraryidnumber, setLibraryidnumber] = useState("");
  const [authormaster_id, setAuthormaster_id] = useState("");
  const [checkedBox, setCheckedBox] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);
  const [menudata, setMenudata] = useState([]);
  const [open, setOpen] = useState(false);
  const [ipAddress, setIpAddress] = useState<string>("");

  // Reactivate Account States
  const [showReactivate, setShowReactivate] = useState(false);
  const [userName, setUserName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginDetails = useAppSelector((state) => state.login);
  const informaticscustomer_id = loginDetails.informaticscustomer_id;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (!event.target.checked) {
      setAuthorField(false);
    } else {
      setAuthorField(true);
    }
  };

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data?.ip);
      } catch (error) {
        setIpAddress("");
        console.log("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);

  useEffect(() => {
    getDetailsByIp();
  }, [ipAddress]);

  async function getDetailsByIp() {
    if (!ipAddress) return;
    if (loginDetails.informaticscustomer_id) return;

    const response = await getCustomerDetailsByIp(ipAddress);
    if (response === null || response === "null") {
      notify(
        "warning",
        "Please register within the Institutional access range"
      );
    }
    if (Array.isArray(response) && response?.length > 1) {
      dispatch(
        setModal({
          modalType: "USER_SELECT",
          modalProps: {
            open: true,
            userArray: response,
          },
        })
      );
    } else if (Array.isArray(response) && response?.length === 1) {
      let data = response[0];
      sessionStorage.setItem(
        "informaticscustomer_id",
        data?.informaticscustomer_id?.toString()
      );
      sessionStorage.setItem("isProfileUser", "false");
      dispatch(
        loginSuccess({
          informaticscustomer_id: data?.informaticscustomer_id,
        })
      );
      window.location.reload();
    }
  }

  const handleAgreementCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedBox(event.target.checked);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setOtherField((event.target as HTMLInputElement).value);
    if (!(event.target.value === "6")) {
      setInputBox(false);
    } else {
      setInputBox(true);
    }
  };

  const [identityValue, setIdentityValue] = useState("libraryId");

  const handleIdentity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIdentityValue((event.target as HTMLInputElement).value);
    if (identityValue === "libraryId") {
      setInstitutionalIDField(true);
      setLibraryIDField(false);
    } else {
      setInstitutionalIDField(false);
      setLibraryIDField(true);
    }
  };

  useEffect(() => {
    if (identityValue === "libraryId") {
      setLibraryidnumber("");
    }
  }, [identityValue]);

  useEffect(() => {
    if (identityValue === "libraryId") {
      setInstituteidnumber("");
    }
  }, [identityValue]);

  useEffect(() => {
    const getData = async () => {
      const allUserTypes = await getAllUserTypes();

      if (allUserTypes) {
        //Get all L3 subjects
        const menulistdata: any = allUserTypes?.map((item: any) => item);
        setMenudata(menulistdata);

        const userList: any[] = [];
        menulistdata[0].forEach((element: any, index: number) => {
          console.log("Element Key", element);
          let key = (index + 1).toString();
          userList[index] = {
            label: element[key],
            value: key,
          };
          setUtype(userList);
        });
      }
    };

    getData();
  }, []);

  const handleSignup = async (e: any) => {
    e.preventDefault();
    setErrorFlag(true);
    console.log("Error Flag Status", errorFlag);
    if (!informaticscustomer_id) {
      notify("error", "Please register within the Institutional access range");
    }

    if (inputBox === true) {
      setErrorFlag(false);
    }

    if (!checkedBox) return;

    // dispatchEvent(signupPending());

    if (
      emailid &&
      firstname &&
      lastname &&
      mobile &&
      checkedBox &&
      phoneRegex.test(mobile.trim()) &&
      emailRegex.test(emailid.trim()) &&
      passwordRegex.test(password) &&
      password === confirmpassword
    ) {
      try {
        const userdetailsjson = {
          data: [
            {
              emailid: emailid,
              informaticscustomer_id: informaticscustomer_id,
              // datusertypeid: datusertypeid,
              datusertypeid: otherfield,
              firstname: firstname,
              lastname: lastname,
              mobile: mobile,
              instituteidnumber: instituteidnumber,
              libraryidnumber: libraryidnumber,
              orcid: authormaster_id,
              department: department,
              designation: null,
              isauthorflag: false,
              microsoftacademicid: null,
              googlescholarid: null,
              crossrefid: null,
              authormaster_id: null,
              // datprofiletype_id: '3',
              datprofiletype_id: otherfield,
              profileuserimagepath: "",
              areaofinterest: "",
            },
          ],
        };

        const querydata: any = await userSignup({
          userdetailsjson,
          password,
        });

        console.log("Authorization", querydata);

        dispatch(signupSuccess(querydata));

        if (querydata.data[0][0].message === "User already exists") {
          // setErrorFlag(true)
          setOpen(true);
          setErrorMessage(querydata.data[0][0].message);
        } else if (
          querydata.data[0][0].message ===
          "Account deactivated. Want to reactivate?"
        ) {
          setShowReactivate(true);
          setUserName(querydata.data[0][0]?.user_name);
          return;
        } else if (querydata.data[0][0].status === "Success") {
          const params = { profileuserid: querydata.data[0][0].user_id };
          usagelogApi(
            querydata?.data[0][0]?.user_id,
            informaticscustomer_id,
            null,
            1,
            null,
            null,
            null,
            null,
            null,
            null,
            ipAddress,
            null,
            null,
            1,
            null
          );

          //If Profile user ? go-to search page : go-to login
          http
            .get(
              "/User/get_usermaster_details_by_id/get_usermaster_details_by_id",
              { params }
            )
            .then(async (res: AxiosResponse<GetUserProfileRoot>) => {
              if (res.data.data[0][0].profile_type) {
                // Not an Institution
                const response: any = await userLogin({
                  email: res.data.data[0][0]?.emailid,
                  password,
                  ipAddress: 0,
                });

                // clearing user data
                dispatch(reset());
                dispatch(clearCustomerDetails());
                dispatch(clearDataTypeFilters());
                sessionStorage.clear();

                // updating user data
                sessionStorage.setItem("session_key", response?.session_key);

                //Save the user sessio in local storage
                sessionStorage.setItem("csrf_token", response?.csrf_token);
                sessionStorage.setItem("user_id", response?.user_id);
                sessionStorage.setItem("preference", "false");

                sessionStorage.setItem(
                  "informaticscustomer_id",
                  response?.informaticscustomer_id
                );
                sessionStorage.setItem("isIPLogin", "false");
                dispatch(loginSuccess(response));
                navigate("/initialSearch");

                return;
              } else {
                setOpen(false);
                navigate("/login");
              }
            });
        }
      } catch (error) {
        console.log("Error State", error);
        dispatch(signupFail(error));
      }
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorFlag(false);
  };

  return (
    <>
      {errorMessage && (
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={errorFlag}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errorMessage}
            </Alert>
          </Snackbar>
        </Stack>
      )}
      <div className="container">
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          component={Paper}
          square
          className="container-info"
          sx={{
            justify: "space-between",
            alignItems: "100%",
            overflowY: "auto",
          }}
        >
          <img src={logo} alt="" className="logoStyle" />
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Box
                component="form"
                noValidate
                sx={{
                  marginTop: "6%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Item sx={{ marginTop: "7%" }}>
                  <Typography
                    component="h1"
                    className="heading"
                    fontWeight={700}
                    fontSize={34}
                    fontFamily={"Lora"}
                  >
                    Register
                  </Typography>
                </Item>
                <Item sx={{ marginLeft: "8px", marginTop: "1%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      padding: "0%",
                      gap: "10px",
                    }}
                  >
                    <FormControl variant="outlined" sx={{ width: "100%" }}>
                      <InputLabel
                        sx={{ color: "#9CA3AF" }}
                        required
                        htmlFor="outlined-adornment-firstname"
                      >
                        First Name
                      </InputLabel>
                      <OutlinedInput
                        type="text"
                        name="firstname"
                        autoComplete="off"
                        id="firstname"
                        sx={Styles.text}
                        value={firstname}
                        onChange={(e: any) => setFirstname(e.target.value)}
                        label="First Name"
                      />
                    </FormControl>

                    {!firstname.trim() && errorFlag && (
                      <Typography sx={Styles.errorMessage}>
                        First name required
                      </Typography>
                    )}
                    {!letters.test(firstname.trim()) &&
                      errorFlag &&
                      firstname.trim() && (
                        <Typography sx={Styles.errorMessage}>
                          Enter valid First Name
                        </Typography>
                      )}

                    <FormControl variant="outlined" sx={{ width: "100%" }}>
                      <InputLabel
                        sx={{ color: "#9CA3AF" }}
                        required
                        htmlFor="outlined-adornment-firstname"
                      >
                        Last Name
                      </InputLabel>
                      <OutlinedInput
                        type="text"
                        name="lastname"
                        autoComplete="off"
                        id="lastname"
                        sx={Styles.text}
                        value={lastname}
                        onChange={(e: any) => setLastname(e.target.value)}
                        label="Last Name"
                      />
                    </FormControl>

                    {!lastname.trim() && errorFlag && (
                      <Typography sx={Styles.errorMessage}>
                        Last name required
                      </Typography>
                    )}

                    {!letters.test(lastname.trim()) &&
                      errorFlag &&
                      lastname.trim() && (
                        <Typography sx={Styles.errorMessage}>
                          Enter valid Last Name
                        </Typography>
                      )}
                  </Box>
                </Item>

                <Item>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel
                      sx={{ color: "#9CA3AF" }}
                      required
                      htmlFor="outlined-adornment-email"
                    >
                      Email
                    </InputLabel>

                    <OutlinedInput
                      id="email"
                      name="email"
                      autoComplete="off"
                      sx={Styles.text}
                      value={emailid}
                      onChange={(e: any) => setEmailid(e.target.value)}
                      label="Email"
                      type="email"
                    />
                  </FormControl>

                  {!emailid.trim() && errorFlag && (
                    <Typography sx={Styles.errorMessage}>
                      Email required
                    </Typography>
                  )}
                  {!emailRegex.test(emailid.trim()) &&
                    errorFlag &&
                    emailid.trim() && (
                      <Typography sx={Styles.errorMessage}>
                        Invalid Email
                      </Typography>
                    )}
                </Item>
                <Item>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel
                      sx={{ color: "#9CA3AF" }}
                      required
                      htmlFor="outlined-adornment-phonenumber"
                    >
                      Mobile Number
                    </InputLabel>

                    <OutlinedInput
                      id="phonenumber"
                      name="phonenumber"
                      autoComplete="off"
                      sx={Styles.text}
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      label="Mobile Number"
                    />
                  </FormControl>

                  {!mobile.trim() && errorFlag && (
                    <Typography sx={Styles.errorMessage}>
                      Mobile number required
                    </Typography>
                  )}
                  {!phoneRegex.test(mobile.trim()) &&
                    errorFlag &&
                    mobile.trim() && (
                      <Typography sx={Styles.errorMessage}>
                        Invalid mobile number
                      </Typography>
                    )}
                </Item>

                <Item>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel
                      sx={{ color: "#9CA3AF" }}
                      required
                      htmlFor="outlined-adornment-password"
                    >
                      Password
                    </InputLabel>
                    <OutlinedInput
                      name="password"
                      autoComplete="off"
                      sx={Styles.text}
                      style={{ marginTop: "1%" }}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      id="outlined-adornment-password"
                      type={showPassword ? "text " : "password"}
                      label="Password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={handleClickShowPassword}
                          >
                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                  {!password && errorFlag && (
                    <Typography sx={Styles.errorMessage}>
                      Password required
                    </Typography>
                  )}
                  {!passwordRegex.test(password) && errorFlag && password && (
                    <Typography sx={Styles.errorMessage}>
                      Password must
                      {!minLengthCheck.test(password.trim()) &&
                        " be at least 6 characters, "}
                      {!lowerCaseCheck.test(password.trim()) &&
                        " contain a lowercase letter, "}
                      {!upperCaseCheck.test(password.trim()) &&
                        " contain an uppercase letter, "}
                      {!specialCharacterCheck.test(password.trim()) &&
                        " include a special character, "}
                      {!numberCheck.test(password.trim()) && " have a number, "}
                      {!whitespaceCheck.test(password) &&
                        " not have whitespace."}
                    </Typography>
                  )}
                </Item>

                <Item>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel
                      sx={{ color: "#9CA3AF" }}
                      required
                      htmlFor="outlined-adornment-confirmpassword"
                    >
                      Confirm Password
                    </InputLabel>
                    <OutlinedInput
                      name="confirmpassword"
                      sx={Styles.text}
                      style={{ marginTop: "1%" }}
                      value={confirmpassword}
                      onChange={(e) => setConfirmpassword(e.target.value)}
                      id="outlined-adornment-password"
                      type={showConfirmPassword ? "text " : "password"}
                      label="Confirm Password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={handleClickShowConfirmPassword}
                          >
                            {!showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                  {(!confirmpassword.trim() || password !== confirmpassword) &&
                    errorFlag &&
                    password.trim() && (
                      <Typography sx={Styles.errorMessage}>
                        Password does not match
                      </Typography>
                    )}
                </Item>

                <Item>
                  <FormControl fullWidth>
                    <InputLabel required sx={{ color: "#9CA3AF" }}>
                      User type
                    </InputLabel>
                    <Select
                      sx={{
                        width: "100%",
                        background: "#fffff",
                        fontFamily: "Poppins",
                        textAlign: "left",
                        fontWeight: 500,
                        fontSize: "18px",
                        color: "#111928",
                      }}
                      // sx={Styles.text} style={{ marginTop: '1%' }}
                      value={otherfield}
                      label="User type"
                      onChange={handleChange}
                    >
                      {Array.isArray(utype) &&
                        utype?.map((listval: any) => {
                          return (
                            <MenuItem
                              key={listval.value}
                              value={listval.value}
                              sx={{
                                fontWeight: 500,
                                fontSize: "14px",
                                fontFamily: "Helvetica Neue",
                              }}
                            >
                              {listval.label}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {!otherfield.trim() && errorFlag && (
                    <Typography sx={Styles.errorMessage}>
                      Select User Type
                    </Typography>
                  )}

                  {/* {errorFlag && !inputBox ? <Typography sx={{ color: "red", fontFamily: 'Poppins', textAlign: 'left', fontSize: '12px' }}>Select User Type</Typography> : null} */}
                </Item>
                {inputBox ? (
                  <Item>
                    <FormControl variant="outlined" sx={{ width: "100%" }}>
                      <OutlinedInput
                        name="othertext"
                        // autoComplete="confirmpassword"
                        sx={Styles.text}
                        style={{ marginTop: "2%" }}
                        // sx={{ width: '100%', background: '#fffff', fontFamily: 'Poppins', marginTop: '2%' }}
                        value={designation}
                        onChange={(e: any) => setDesignation(e.target.value)}
                        id="outlined-adornment-othertext"
                        type="text"
                        // label="Confirm Password"
                      />
                    </FormControl>
                    {!designation.trim() && errorFlag && (
                      <Typography sx={Styles.errorMessage}>
                        Enter Other User Type
                      </Typography>
                    )}
                  </Item>
                ) : null}

                <Item>
                  <Box textAlign="left" width={250}>
                    <Box>
                      <Typography
                        fontWeight={700}
                        fontFamily={"Lora"}
                        sx={{ marginTop: "1%" }}
                      >
                        Select any one identity
                      </Typography>
                    </Box>
                    <Box
                      gap={2}
                      display="flex"
                      sx={{ marginTop: "2%" }}
                      width={"100%"}
                      //  width={800}
                    >
                      <RadioGroup
                        sx={{ width: "115%", height: "24px" }}
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={identityValue}
                        onChange={handleIdentity}
                      >
                        <FormControlLabel
                          value="libraryId"
                          // checked={libraryId === 'libraryId'} onChange={(e: any) => setLibraryId(e.target.value)}
                          control={<Radio />}
                          label={
                            <Typography
                              sx={{
                                fontWeight: 700,
                                fontSize: "16px",
                                fontFamily: "Lora",
                              }}
                            >
                              Your library ID
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="institutionalId"
                          label={
                            <Typography
                              sx={{
                                fontWeight: 700,
                                fontSize: "16px",
                                fontFamily: "Lora",
                              }}
                            >
                              Your Institutional ID
                            </Typography>
                          }
                          // checked={institutionalID === 'institutionalId'} onChange={(e: any) => setInstitutionalID(e.target.value)}
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </Box>
                  </Box>
                </Item>

                {institutionalIDField ? (
                  <Item sx={{ marginTop: "3%" }}>
                    <FormControl variant="outlined" sx={{ width: "100%" }}>
                      <InputLabel
                        sx={{ color: "#9CA3AF" }}
                        htmlFor="outlined-adornment-libraryid"
                      >
                        Enter your Institutional ID
                      </InputLabel>
                      <OutlinedInput
                        name="libraryid"
                        autoComplete="off"
                        sx={Styles.text}
                        // sx={{ width: '100%', background: '#fffff', fontFamily: 'Poppins' }}
                        value={instituteidnumber}
                        onChange={(e: any) =>
                          setInstituteidnumber(e.target.value)
                        }
                        id="outlined-adornment-libraryid"
                        type="text"
                        label="Enter your Institutional ID"
                      />
                    </FormControl>
                  </Item>
                ) : (
                  <Item sx={{ marginTop: "3%" }}>
                    <FormControl variant="outlined" sx={{ width: "100%" }}>
                      <InputLabel
                        sx={{ color: "#9CA3AF" }}
                        htmlFor="outlined-adornment-libraryid"
                      >
                        Enter your library ID
                      </InputLabel>
                      <OutlinedInput
                        name="libraryid"
                        autoComplete="off"
                        sx={Styles.text}
                        // sx={{ width: '100%', background: '#fffff', fontFamily: 'Poppins' }}
                        value={libraryidnumber}
                        onChange={(e: any) =>
                          setLibraryidnumber(e.target.value)
                        }
                        id="outlined-adornment-libraryid"
                        type="text"
                        label="Enter your library ID"
                      />
                    </FormControl>
                  </Item>
                )}

                <Item>
                  <Box style={{ textAlign: "left", display: "flex" }}>
                    <Typography fontWeight={700} fontFamily={"Lora"}>
                      Are you an Author?
                    </Typography>

                    <Checkbox
                      checked={checked}
                      onChange={handleCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{
                        color: "#7852FB",
                        "&.Mui-checked": {
                          color: "#7852FB",
                        },
                        marginTop: "-2%",
                      }}
                    />
                  </Box>
                </Item>

                {authorField ? (
                  <Item sx={{ mt: 0 }}>
                    <FormControl variant="outlined" sx={{ width: "100%" }}>
                      <InputLabel
                        sx={{ color: "#9CA3AF" }}
                        htmlFor="outlined-adornment-libraryid"
                      >
                        Enter your ORCID ID
                      </InputLabel>
                      <OutlinedInput
                        name="orcid"
                        sx={Styles.text}
                        style={{ marginTop: "0%" }}
                        // sx={{ width: '100%', background: '#fffff', fontFamily: 'Poppins', marginTop: '0%' }}
                        value={authormaster_id}
                        onChange={(e: any) =>
                          setAuthormaster_id(e.target.value)
                        }
                        id="outlined-adornment-orcid"
                        type="text"
                        label="Enter your ORCID ID"
                      />
                    </FormControl>
                  </Item>
                ) : null}

                <Item>
                  <Box className="terms">
                    <Checkbox
                      className="terms_checkbox"
                      onChange={handleAgreementCheck}
                      checked={checkedBox}
                      value={checked}
                      required={true}
                    />{" "}
                    <p className="required">*</p>
                    <Typography
                      textAlign="left"
                      fontFamily={"Poppins"}
                      fontSize="13px"
                      sx={{ pl: "2%", color: "#9CA3AF" }}
                    >
                      I have read and agree to receive electronic communication{" "}
                      <br />
                      about my accounts and services in accordance with
                      {/* <Link to="/" style={{ color: "#7852FB" }}>
                        {" "} */}
                      J-Gate <br /> Agreement.
                      {/* </Link> */}
                    </Typography>
                  </Box>
                </Item>

                {checkedBox == false && errorFlag ? (
                  <Typography sx={Styles.errorMessage}>
                    Please agree to the terms and conditions
                  </Typography>
                ) : null}

                <Item sx={{ marginTop: "2%" }}>
                  <CommonButton
                    buttontitle="Create Account"
                    onClick={handleSignup}
                  />
                </Item>

                <Item>
                  {/* <Box>
                    <Typography
                      fontFamily={"Poppins"}
                      textAlign="center"
                      width="400"
                      fontWeight={400}
                      fontSize={12}
                      sx={{ marginTop: "2%", color: Colors.black }}
                    >
                      By clicking “Create Account” I agree to
                      J-Gate&apos;s&nbsp;
                      <span className="term_link">
                        Terms of service{" "}
                      </span>
                      <br /> and{" "}
                      <span className="term_link">
                        Privacy policy.
                      </span>
                    </Typography>
                  </Box> */}
                </Item>
                <Item>
                  <Box>
                    <Typography
                      fontFamily={"Poppins"}
                      fontWeight={500}
                      textAlign="center"
                      fontSize={14}
                      sx={{ marginTop: "2%" }}
                    >
                      Already have a JGate account?&nbsp;
                      <Link className="login_link" to="/login">
                        Log In
                      </Link>
                    </Typography>
                  </Box>
                </Item>
              </Box>
            </div>
          </div>
        </Grid>

        <Grid
          className="container-main"
          item
          xs={false}
          sm={6}
          md={6}
          sx={{
            backgroundColor: "#7852FB",
            height: "1024px",
          }}
        >
          <img
            src={SignUpImage}
            alt="SignUpImage"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Grid>
      </div>

      {/* REACTIVATE MODAL */}
      <ReactivateProfile
        user_name={userName}
        show={showReactivate}
        onClose={() => setShowReactivate(false)}
      />
    </>
  );
};

export default SignUp;

const Styles = {
  errorMessage: {
    color: "red",
    fontFamily: "Poppins",
    textAlign: "left",
    fontSize: "12px",
  },

  text: {
    width: "100%",
    background: "#fffff",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "18px",
    color: "#111928",
  },
};
