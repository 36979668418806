import { createSlice } from "@reduxjs/toolkit";

type Key =
  | "search-term"
  | "data_type"
  | "subject"
  | "author"
  | "journal"
  | "speaker"
  | "research"
  | "researcher"
  | "journalRank"
  | "countryOfPublication"
  | "countryOfPublishingAuthor"
  | "publisher"
  | "institutions"
  | "yearFrom"
  | "conference_name"
  | "magazine"
  | "university_name"
  | "inventor";

interface FilterFormat {
  key: string | Key;
  value: string;
}

export type InitialState = {
  loading: boolean;
  sourceType: string[];
  subject: string[];
  author: string[];
  speaker: string[];
  research: string[];
  researcher: string[];
  inventor:string[];
  journal: string[];
  countryOfPublication: string[];
  publisher: string[];
  institutions: string[];
  journalRank: string[];
  countryOfPublishingAuthor: string[];
  sort: string;
  sourceIndex: boolean;
  yearFrom: string | string[];
  monthFrom: string;
  dateFrom: string;
  openAccess: boolean;
  earlyOnline: boolean;
  retractedArticle: boolean;
  datesetAvailability: boolean;

  jgateCollection: boolean;
  myLibraryCollection: boolean;
  libraryOpac: boolean;
  myPersonalLibCollection: boolean;
  consortiaCollection: boolean;
  onosCollection: boolean;
  dspace:boolean;
  isOpac_dspace:boolean;

  allAppliedFilter: FilterFormat[];
  collectionValue?: string;

  //for lateral search
  conference_name: string[];
  university_name: string[];
  resourcemaster_id: number[];
};

const initialState: InitialState = {
  loading: false,
  sourceType: [],
  subject: [],
  author: [],
  speaker: [],
  inventor:[],
  research: [],
  researcher: [],
  journal: [],
  countryOfPublication: [],
  publisher: [],
  institutions: [],
  journalRank: [],
  sort: "dateofpublication desc",
  countryOfPublishingAuthor: [],
  sourceIndex: false,
  yearFrom: "",
  monthFrom: "",
  dateFrom: "",
  openAccess: false,
  earlyOnline: false,
  retractedArticle: false,
  datesetAvailability: false,

  jgateCollection: false,
  myLibraryCollection: false,
  libraryOpac: false,
  myPersonalLibCollection: false,
  consortiaCollection: false,
  onosCollection: false,
  dspace:false,
  isOpac_dspace:false,

  allAppliedFilter: [],
  collectionValue: "",

  conference_name: [],
  university_name: [],
  resourcemaster_id: [],
};

export const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    updateSourceType(state, { payload }) {
      state.sourceType = payload;
    },
    updateSubject(state, { payload }) {
      state.subject = payload;
    },
    updateAuthor(state, { payload }) {
      state.author = payload;
    },
    updateSpeaker(state, { payload }) {
      state.speaker = payload;
    },
    updateResearch(state, { payload }) {
      state.research = payload;
    },
    updateResearcher(state, {payload}) {
      state.researcher = payload;
    },
    updateInventor(state,{payload}){
      state.inventor = payload
    },
    updateJournal(state, { payload }) {
      state.journal = payload;
    },
    updateCountryOfPublication(state, { payload }) {
      state.countryOfPublication = payload;
    },
    updatePublisher(state, { payload }) {
      state.publisher = payload;
    },
    updateInstitution(state, { payload }) {
      state.institutions = payload;
    },
    updateJournalRank(state, { payload }) {
      state.journalRank = payload;
    },
    updateSort(state, { payload }) {
      state.sort = payload;
    },
    updateCountryOfPublishingAuthor(state, { payload }) {
      state.countryOfPublishingAuthor = payload;
    },

    updateSourceIndex(state, { payload }) {
      state.sourceIndex = payload;
    },
    updateOpenAccess(state, { payload }) {
      state.openAccess = payload;
    },
    updateRetractedArticle(state, { payload }) {
      state.retractedArticle = payload;
    },
    updateDateset(state, { payload }) {
      state.datesetAvailability = payload;
    },

    updateYearFrom(state, { payload }) {
      state.yearFrom = payload;
    },
    updateMonthFrom(state, { payload }) {
      state.monthFrom = payload;
    },
    updateDateFrom(state, { payload }) {
      state.dateFrom = payload;
    },
    updateEarlyOnline(state, { payload }) {
      state.earlyOnline = payload;
    },

    clearPublicationFilter(state) {
      state.yearFrom = "";
      state.dateFrom = "";
      state.monthFrom = "";
      state.earlyOnline = false;
    },

    clearMonthFromFilter(state){
      state.monthFrom="";
      state.dateFrom="";
    },


    // Collection filter
    updateJgateCollection(state, { payload }) {
      state.jgateCollection = payload;
    },

    updateConsortiaCollection(state, { payload }) {
      state.consortiaCollection = payload;
    },

    updateMyLibraryCollection(state, { payload }) {
      state.myLibraryCollection = payload;
    },

    updateOnosCollection(state, { payload }) {
      state.onosCollection = payload;
    },

    updataLibraryOpac(state, { payload }) {
      state.libraryOpac = payload;
    },

    updateDSPACECollection(state, { payload }) {
      state.dspace = payload;
    },

    updateIsOpac_dspace(state, { payload }) {
      state.isOpac_dspace = payload;
    },

    


    updatePersonalLibrary(state, { payload }) {
      state.myPersonalLibCollection = payload;
    },

    clearAllFilters(state) {
      state.sourceType = [];
      state.subject = [];
      state.author = [];
      state.speaker = [];
      state.research = [];
      state.researcher = [];
      state.journal = [];
      state.countryOfPublication = [];
      state.publisher = [];
      state.institutions = [];
      state.journalRank = [];
      state.countryOfPublishingAuthor = [];
      state.retractedArticle = false;
      state.datesetAvailability = false;
      state.sourceIndex = false;
      state.openAccess = false;
      state.yearFrom = "";
      state.conference_name = [];
      state.university_name = [];
      state.resourcemaster_id = [];
      state.inventor = [];

      
      // For DF-1448

      // state.jgateCollection = false;
      // state.myLibraryCollection = false;
      // state.libraryOpac = false;
      // state.myPersonalLibCollection = false;
      // state.consortiaCollection = false;
      // state.onosCollection = false;
      // state.collectionValue = "";
    },

    clearSource(state) {
      state.sourceType = [];
    },
    clearSubject(state) {
      state.subject = [];
    },
    updateLoadingState(state, { payload }) {
      state.loading = payload;
    },
    clearLimits(state) {
      state.jgateCollection = false;
      state.myLibraryCollection = false;
      state.libraryOpac = false;
      state.myPersonalLibCollection = false;
      state.consortiaCollection = false;
      state.onosCollection = false;
      state.collectionValue = "";
    },

    updateAllFilter(state, { payload }) {
      if (Array.isArray(payload)) {
        state.allAppliedFilter = [...state.allAppliedFilter, ...payload];
      } else {
        state.allAppliedFilter = [...state.allAppliedFilter, payload];
      }
    },
    removeAllFilterType(state, { payload }) {
      state.allAppliedFilter = state.allAppliedFilter.filter(
        (obj) => obj?.value !== payload
      );
    },
    clearAllFiltersValue(state) {
      state.allAppliedFilter = [];
    },
    spliceAllFilterArray(state) {
      state.allAppliedFilter.splice(1);
    },

    // Function to remove all filters except the searchterm
    removeAppliedFilters(state) {
      state.allAppliedFilter = state.allAppliedFilter.filter(
        (obj) => obj?.key === "search-term"
      );
    },
    

    updateCollectionValue(state, { payload }) {
      state.collectionValue = payload;
    },

    // for conference lateral search
    updateConfrence(state, { payload }) {
      state.conference_name = payload;
    },
    updateUniversityName(state, { payload }) {
      state.university_name = payload;
    },
    updateResourceId(state, { payload }) {
      state.resourcemaster_id = payload;
    },
  },
});

export const {
  updateSourceType,
  updateSubject,
  clearSource,
  clearSubject,
  updateLoadingState,
  updateAuthor,
  updateSpeaker,
  updateResearch,
  updateJournal,
  updateSourceIndex,
  updateCountryOfPublication,
  updatePublisher,
  updateInstitution,
  updateJournalRank,
  updateSort,
  updateCountryOfPublishingAuthor,
  clearAllFilters,
  updateYearFrom,
  updateMonthFrom,
  updateDateFrom,
  updateOpenAccess,
  updateEarlyOnline,
  updateRetractedArticle,
  updateDateset,
  clearPublicationFilter,
  updateJgateCollection,
  updateConsortiaCollection,
  updateMyLibraryCollection,
  updateOnosCollection,
  updataLibraryOpac,
  updatePersonalLibrary,
  clearLimits,
  updateAllFilter,
  removeAllFilterType,
  clearAllFiltersValue,
  removeAppliedFilters,
  updateCollectionValue,
  spliceAllFilterArray,
  updateConfrence,
  updateUniversityName,
  updateResourceId,
  updateResearcher,
  clearMonthFromFilter,
  updateInventor,
  updateDSPACECollection,
  updateIsOpac_dspace
} = filterSlice.actions;

export default filterSlice.reducer;
