import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Text from "@mui/material/Typography";
// Icons
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// Assets
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Tooltip } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import { useSelector } from "react-redux";
import { searchHistoryApi } from "../../api/searchHistoryapi";
import { usagelogApi } from "../../api/usageReportApi";
import logo from "../../assets/img/logo.png";
import { CustomWidthTooltip } from "../../screens/SearchHistory/QueryBuilder";
import { useAppDispatch } from "../../store/hooks";
import { updateIsNavigatingBack } from "../../store/slice/searchSlice";
import { notify } from "../../utils/Notify";
import { throttleFunction } from "../../utils/helper";
import { useMainNav } from "./MainNav.hook";
import { navStyles as sx } from "./Nav.style";
import ProfileMenu from "./ProfileMenu";
import SideDrawer from "./SideDrawer";
import "./style.css";
import api from "../../api/endpoints";

const MainNav = () => {
  const {
    navigate,
    drawerRef,
    appBarRef,
    toggleNav,
    anchorEl,
    closeNav,
    customerDetails,
    markedResult,
    open,
    me,
    closeMenu,
    handleDrawerToggle,
    handleClick,
  } = useMainNav();
  const profileData = useSelector((state: any) => state.login);
  const [searchhistoryData, setSearchHistoryData] = useState([]);
  const [tooltipString, setTooltipString] = useState("Search History");

  const profileData1 = useSelector(
    (state: any) => state.customer.customerDetails
  );

  const dispatch = useAppDispatch();

  // const ailoginTocken = customerDetails;

  const logBasicSearchUsageData = (usageActionID: any) => {
    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      null,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  function AIToolIcon() {
    return (
      <div>
        <AutoAwesomeIcon
          sx={{
            fontSize: 25,
            color: "orange",
            "&:hover": {
              color: "purple",
            },
            marginTop: "8px",
          }}
        />
      </div>
    );
  }

  const fetchAIToken = async () => {
    try {
      const userId = sessionStorage.getItem("user_id");
      const response = await fetch(
        `${api.baseApi}/api/generate_ai_userlogin/generate_ai_userlogin?user_id=${userId}`
      );
      const data = await response.json();
      const aiToken = data?.data?.[0][0]?.user_token;

      if (api.baseApi === "https://betaapi.jgatenext.com") {
        window.open(
          // `https://ai.jgatenext.com/?token=${aiToken}`,
          `http://20.204.168.47:8000/?token=${aiToken}`,
          "_blank",
          "noopener,noreferrer"
        ); // Open in new tab with token
      } else {
        window.open(
          `https://ai.jgatenext.com/?token=${aiToken}`,
          // `http://20.204.168.47:8000/?token=${aiToken}`,
          "_blank",
          "noopener,noreferrer"
        ); // Open in new tab with token
      }
    } catch (error) {
      console.log("Error fetching AI token:", error);
    }
  };

  const HandleHoverSearchHistory = async () => {
    try {
      let res = await searchHistoryApi();
      setSearchHistoryData(res);
      // DF-2264
      // let tempstring = "";
      // res = res?.filter((obj: any) => obj?.is_delete === false);
      // res.map((item: any) => {
      //   tempstring += "S"
      //   tempstring += item?.row_number;
      //   tempstring +=". ";
      //   tempstring += item?.SearchTerms;
      //   tempstring += `(${item?.result_count})`;
      //   tempstring += "-";
      //   tempstring += item?.search_type;
      //   tempstring += "\n";
      // });
      // if (tempstring) setTooltipString(tempstring);
    } catch (error) {
      console.log(error);
    }
  };

  const throttledSearchHistoryHover = throttleFunction(
    HandleHoverSearchHistory,
    5000
  );

  const handleSearchHistory = async () => {
    logBasicSearchUsageData(96);
    const zero = searchhistoryData?.filter(
      (item: any) => !item?.is_delete
    )?.length;
    if (!searchhistoryData || !zero) {
      notify("warning", "Please make some searches to get search history");
    } else {
      navigate("/searchhistory");
    }
  };

  return (
    <>
      <AppBar color="inherit" position="sticky" className="nav" ref={appBarRef}>
        <Toolbar sx={sx.toolbar}>
          <Stack direction={"row"} alignItems={"center"}>
            {/* Hamburger Menu */}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              {toggleNav ? <CloseIcon /> : <MenuIcon />}
            </IconButton>

            {/* Logo */}
            <img
              src={logo}
              alt="logo"
              className="nav-logo"
              onClick={() => {
                logBasicSearchUsageData(8);
                dispatch(updateIsNavigatingBack(false));
                navigate("/initialSearch");
              }}
            />
            {/* {(profileData1?.banner != "\\x" &&
              profileData1?.banner != null &&
              profileData1?.banner) ||
            (profileData1?.logo !== null &&
              profileData1?.logo !== "\\x" &&
              profileData1?.logo) ? (
              profileData1?.banner &&
              profileData1?.banner != "\\x" &&
              profileData1?.banner != null ? (
                <Stack alignItems={"center"} sx={sx.brandWrapper}>
                  <img src={profileData1?.banner} width={70}></img> 
                  <img
                    src={profileData1?.banner}
                    style={{
                      width: "100px",
                      height: "70px",
                      objectFit: "contain",
                    }}
                  ></img>
                </Stack>
              ) : (profileData1?.banner == "\\x" ||
                  profileData1?.banner == null ||
                  !profileData1?.banner) &&
                profileData1?.logo ? (
                <Stack alignItems={"center"} sx={sx.brandWrapper}>
               <img src={profileData1?.logo} width={70}></img> 
                  <img
                    src={profileData1?.logo}
                    style={{
                      width: "100px",
                      height: "70px",
                      objectFit: "contain",
                    }}
                  ></img>
                </Stack>
              ) : null
            ) : null} */}

            <Box sx={sx.brandWrapper}>
              <Text sx={sx.consortia}>
                {customerDetails?.consortianame
                  ? customerDetails?.consortianame
                  : ""}
              </Text>
              <Text
                sx={{
                  ...sx.name,
                  // fontSize: customerDetails?.consortianame ? "0.75rem" : "1rem",
                  fontSize: customerDetails?.consortianame
                    ? // ? "1.0625rem"
                      "22px"
                    : sx.diplyaName,
                }}
              >
                {customerDetails?.domainname
                  ? customerDetails?.domainname
                  : customerDetails?.displayname}
              </Text>
            </Box>
            {(profileData1?.banner != "\\x" &&
              profileData1?.banner != null &&
              profileData1?.banner) ||
            (profileData1?.logo !== null &&
              profileData1?.logo !== "\\x" &&
              profileData1?.logo) ? (
              profileData1?.banner &&
              profileData1?.banner != "\\x" &&
              profileData1?.banner != null ? (
                // <Stack alignItems={"center"} sx={sx.brandWrapper}>
                <Stack alignItems={"center"} sx={sx.brandWrapper}>
                  {/* <img src={profileData1?.banner} width={70}></img> */}
                  <img
                    src={profileData1?.banner}
                    alt="banner"
                    style={{
                      width: "100px",
                      // height: "70px",
                      // height: "auto",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  ></img>
                </Stack>
              ) : (profileData1?.banner == "\\x" ||
                  profileData1?.banner == null ||
                  !profileData1?.banner) &&
                profileData1?.logo ? (
                <Stack alignItems={"center"} sx={sx.brandWrapper}>
                  {/* <img src={profileData1?.logo} width={70}></img> */}
                  <img
                    src={profileData1?.logo}
                    alt="logo"
                    style={{
                      width: "100px",
                      // height: "70px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  ></img>
                </Stack>
              ) : null
            ) : null}
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <Box sx={sx.iconWrapper}>
              {(customerDetails?.informaticscustomer_id == 18 ||
                customerDetails?.informaticscustomer_id == 4896) && (
                <Tooltip title="J-GateNext AI">
                  <IconButton
                    onClick={() => {
                      fetchAIToken();
                    }}
                    //href="http://20.198.68.235:8501"
                    // href={`https://ai.jgatenext.com/?token=${isAIToken}`}
                    // target="_blank"
                    // rel="noopener noreferrer"
                  >
                    <AIToolIcon />
                  </IconButton>
                </Tooltip>
              )}

              {/* <CustomTooltip title="AI">
                <a
                  href="http://20.198.68.235:8501"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={playStore}
                    alt="img"
                    style={{
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                  />
                </a>
              </CustomTooltip> */}
              <Tooltip title="Search">
                <IconButton
                  onClick={() => {
                    logBasicSearchUsageData(8);
                    dispatch(updateIsNavigatingBack(false));
                    navigate("/initialSearch");
                  }}
                >
                  <SearchOutlinedIcon />
                </IconButton>
              </Tooltip>
              <CustomWidthTooltip title={`${tooltipString}`}>
                <IconButton
                  onClick={handleSearchHistory}
                  onMouseEnter={throttledSearchHistoryHover}
                >
                  <AccessTimeOutlinedIcon />
                </IconButton>
              </CustomWidthTooltip>
              <Tooltip title="Bookmarked Articles">
                <IconButton
                  onClick={() => navigate("/marked-result")}
                  sx={{ fontSize: "14px" }}
                >
                  <BookmarkBorderOutlinedIcon
                    sx={{
                      color: markedResult.length ? sx.markedView : "",
                    }}
                  />{" "}
                  {markedResult.length ? `(${markedResult?.length})` : ""}
                </IconButton>
              </Tooltip>
            </Box>

            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
              onClick={handleClick}
            >
              <Text>
                Hi {me?.firstname} {me?.lastname}
              </Text>
              <Avatar
                sx={{
                  fontSize: "12px",
                  ml: 2,
                  bgcolor: "primary.main",
                  color: "#FFF",
                }}
                alt={me?.firstname}
                src={me?.profileuserimagepath}
              >
                {Array.isArray(me?.firstname) ? me?.firstname[0] : ""}
                {Array.isArray(me?.lastname) ? me?.lastname[0] : ""}
              </Avatar>
              <ArrowDropDownOutlinedIcon />
            </Stack>
          </Stack>

          {/* User Profile Menu */}
          <ProfileMenu
            anchorEl={anchorEl}
            open={open}
            closeMenu={closeMenu}
            profileDetail={me}
          />
        </Toolbar>
      </AppBar>

      {/* Nav Drawer */}
      <SideDrawer
        toggleNav={toggleNav}
        drawerRef={drawerRef}
        closeNav={closeNav}
      />
    </>
  );
};

export default MainNav;
