import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { Colors } from "../utils/constants";
import metaData from "../utils/metadata";

interface P {
  dialogueData?: any;
}

const BookDetails: React.FC<P> = ({ dialogueData }) => {
  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        sx={{ width: "95%", marginInline: "auto", marginTop: "15px" }}
        rowGap={1}
        direction={"column"}
      >
        <Grid item>
          {" "}
          <Typography
            variant="body1"
            sx={{
              fontWeight: "600",
              fontSize: "1.2rem",
              fontFamily: "Lora",
            }}
          >
            <div id="title">
              {dialogueData?.has_chapter
                ? dialogueData?.title
                  ? dialogueData?.title
                  : dialogueData?.book_name
                : dialogueData?.book_name}
            </div>
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              textAlign: "left",
              fontSize: "0.875rem",
              color: Colors.black,
              fontStyle: "italic",
            }}
            // onClick={() => handleResource(dialogueData)}
          >
            {metaData(dialogueData)}
          </Typography>
        </Grid>
        <Grid item>
          {" "}
          {dialogueData?.editors && (
            <Box sx={{ display: "flex", gap: 2 }}>
              <Typography variant="body2">Editor :</Typography>
              <Typography variant="body2">
                {dialogueData?.editors.map((val: any) => val)}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item>
          {dialogueData?.aboutresource ||
          dialogueData?.about_resource ||
          dialogueData.abstract ? (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Box minWidth={"150px"}>
                <Typography
                  variant="body2"
                  sx={{ fontFamily: "Helvetica Neue", fontWeight: "700" }}
                >
                  About Book :
                </Typography>
              </Box>
              <Typography variant="body2">
                <div
                  dangerouslySetInnerHTML={{
                    __html: dialogueData?.aboutresource
                      ? dialogueData?.aboutresource
                      : dialogueData?.about_resource
                      ? dialogueData?.about_resource
                      : dialogueData.abstract,
                  }}
                />
              </Typography>
            </Box>
          ) : null}
        </Grid>
        <Grid item>
          {" "}
          {dialogueData?.p_isbn && (
            <Box sx={{ display: "flex", gap: 2 }}>
              <Typography variant="body2">ISBN :</Typography>
              <Typography variant="body2">{dialogueData?.p_isbn}</Typography>
            </Box>
          )}
        </Grid>
        <Grid item>
          {" "}
          <Box sx={{ display: "flex", gap: 2 }}>
            {" "}
            <Typography variant="body2">Book Link :</Typography>{" "}
            <Typography variant="body2" style={webStyle.informationText}>
              <a
                target="_blank"
                style={{ color: Colors.primary }}
                href={`${dialogueData?.bookurl}`}
                rel="noreferrer"
              >
                {dialogueData?.bookurl}
              </a>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default BookDetails;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  leftBox: {
    width: "90%",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    marginTop: "2%",
    paddingLeft: "3%",
  },
  titleText: {
    width: "20%",
    fontFamily: "Helvetica Neue",
    fontWeight: "700",
    marginLeft: "3%",
  },
  informationText: {
    width: "70%",
  },
  subContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "2%",
  },
};
