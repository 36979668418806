import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useEffect, useMemo, useState } from "react";
import { DoughnutType, Facet, LegendType } from "../modals/Analytics.modal";
import { generateRainbowColors } from "../../utils/ColorGenerator";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  updateCountryOfPublishingAuthor,
  updateCountryOfPublication,
  updateAllFilter,
} from "../../store/slice/filterSlice";
import { onSearch as search } from "../../screens/Search/onSearch";
import { hideModal } from "../../store/slice/modalSlice";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { onAuthorResult as authorsearch } from "../../screens/AuthorFinder/onAuthorResult";
import {
  updateAuthorPage,
  updateAuthorRow,
} from "../../store/slice/authorFinderSlice";
import { titleCase } from "../../utils/helper";

ChartJS.register(ArcElement, Tooltip, Legend);
const chartOptions: ChartOptions<"doughnut"> = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: 30,
  },
  plugins: {
    legend: {
      position: "right",
      display: false,
    },
    tooltip: {
      usePointStyle: true,
      callbacks: {
        title: (ctx) => {
          const title = titleCase(ctx[0].label);
          const count = ctx[0].formattedValue;
          return `${title} (${count})`;
        },
        label: (_ctx) => "",
      },
    },
  },
};

const AnalyticsDonutChart = ({
  chartData,
  count,
  type,
}: {
  chartData: Facet[];
  count: number;
  type: "cop" | "copa";
}) => {
  const dispatch = useAppDispatch();
  const authorReducer = useAppSelector((state) => state.authorReducer);

  const [donutData, setDonutData] = useState<DoughnutType | null>(null);
  const [legends, setLegends] = useState<LegendType | null>(null);
  const [isNoData, setIsNoData] = useState<boolean>(false);

  const colors = useMemo(() => generateRainbowColors(count), [count]);

  useEffect(() => {
    if (chartData?.length > 0) {
      const currentCountArr = chartData.slice(0, count);
      const currentColorArr = colors.slice(0, count);

      const customLegends = currentCountArr?.map((item, i) => {
        return {
          name: item.val,
          count: item.count,
          color: currentColorArr[i],
        };
      });

      const data: DoughnutType = {
        labels: currentCountArr?.map((item) => item.val),
        datasets: [
          {
            data: currentCountArr?.map((item) => item.count),
            backgroundColor: currentColorArr,
            hoverOffset: 21,
            borderWidth: 0,
          },
        ],
      };

      setLegends(customLegends);
      setDonutData(data);
      setIsNoData(false);
    } else {
      setIsNoData(true);
    }
  }, [chartData, colors, count]);

  const handleSelectedValue = (value: string[] | undefined) => {
    const text = value?.[0].replace(/\s*\([^)]*\)/g, "").trim();

    if (type === "copa") {
      dispatch(updateCountryOfPublishingAuthor([text]));
      dispatch(
        updateAllFilter({
          key: "countryOfPublishingAuthor",
          value: text,
        })
      );
    } else if (type === "cop") {
      dispatch(updateCountryOfPublication([text]));
      dispatch(
        updateAllFilter({
          key: "countryOfPublication",
          value: text,
        })
      );
    }

    authorReducer.isAuthorAnalytics
      ? dispatch(updateAuthorPage(1)) &&
        dispatch(updateAuthorRow(15)) &&
        dispatch(authorsearch())
      : dispatch(search("", null, true));

    dispatch(hideModal());
  };

  return (
    <>
      {isNoData ? (
        <Box
          sx={{
            height: "63vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 1,
            fontSize: "1.4rem",
          }}
        >
          No Data Available
        </Box>
      ) : (
        <Box
          sx={{
            height: "63vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            p: 1,
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "50%",
              display: "grid",
              placeItems: "center",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            {donutData && (
              <Doughnut
                data={donutData}
                options={{
                  ...chartOptions,
                  onClick: (_f, _u, chart) =>
                    handleSelectedValue(chart.tooltip?.title),
                }}
              />
            )}
          </Box>

          <Grid
            container
            spacing={2}
            sx={{
              height: "100%",
              width: "50%",
              overflowY: "auto",
              p: 1,
              alignItems: "center",
            }}
          >
            {legends &&
              legends?.map((item, i) => (
                <>
                  <Grid xs={1}>
                    <Box
                      sx={{
                        width: "16px",
                        height: "16px",
                        backgroundColor: item.color,
                        borderRadius: "50%",
                        mr: 1,
                      }}
                    />
                  </Grid>
                  <Grid xs={9}>
                    <Text
                      noWrap
                      width={"100%"}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item.name}
                    </Text>
                  </Grid>
                  <Grid xs={2}>
                    <Text sx={{ ml: "auto", textTransform: "capitalize" }}>
                      {item.count?.toLocaleString("en-US")}
                    </Text>
                  </Grid>
                </>
              ))}
          </Grid>

          <Grid xs={2}>
            <Text
              sx={{
                position: "relative",
                writingMode: "vertical-rl",
                transform: "rotate(180deg)",
                color: "blue",
                marginLeft: "6px",
              }}
            >
              Document Count
            </Text>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default AnalyticsDonutChart;
