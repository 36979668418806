import React, { useEffect, useState } from "react";
import { getParamsByName } from "../utils/helper";
import { profileDetailsAPI, validateScholarLoginByToken } from "../api/userApi";
import { useDispatch } from "react-redux";
import { loginFail, loginSuccess } from "../store/slice/loginSlice";
import getCustomerDetailsByIp from "../api/getCustomerDetailByIp";
import { usagelogApi } from "../api/usageReportApi";
import { updateCaptchaStatus } from "../store/slice/captchaVerification";
import { Alert, Snackbar, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/spinner";

const SSO = () => {
  const customer_id = getParamsByName("customer_id");
  const login_token = getParamsByName("login_token");
  const is_lib_admin = getParamsByName("is_lib_admin");
  const is_scholar = getParamsByName("is_scholar");
  console.log("is_lib_admin", is_lib_admin);
  console.log("is_scholar", is_scholar);
  const [customerid, setCustomerid] = useState(customer_id);
  const [token, setToken] = useState(login_token);
  const [ipAddress, setIpAddress] = React.useState<string>("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [link, setLink] = useState("");
  const [isLoading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SSOLogin = async () => {
    try {
      const isAuth: any = await validateScholarLoginByToken({
        customerid,
        token,
        is_lib_admin,
        navigate,
      });
      setLoading(false);
      dispatch(loginSuccess(isAuth));
      const userid = isAuth?.user_id;

      console.log("isAuth", isAuth);

      const UserData: any = await profileDetailsAPI({ userid });
      let ipAddress;
      const fetchIpAddress = async () => {
        try {
          const responses = await fetch("https://api.ipify.org?format=json");
          const datas = await responses.json();
          ipAddress = datas?.ip;
        } catch (error) {
          setIpAddress("0");
          console.log("Error fetching IP address:", error);
        }
      };
      fetchIpAddress();
      let ipResponse;
      if (!ipAddress) {
        // ipResponse = await getCustomerDetailsByIp(ipAddress);
      } else {
        ipResponse = await getCustomerDetailsByIp(ipAddress);
      }

      // usageReport("4") From the Library admin
      if (isAuth?.is_lib_admin == "true") {
        usagelogApi(
          isAuth.user_id,
          isAuth.informaticscustomer_id,
          null,
          4,
          null,
          null,
          null,
          null,
          null,
          null,
          ipAddress,
          null,
          isAuth.session_key,
          1,
          null
        );
      }

      // usageReport("8");
      usagelogApi(
        isAuth.user_id,
        isAuth.informaticscustomer_id,
        null,
        8,
        null,
        null,
        null,
        null,
        null,
        null,
        ipAddress,
        null,
        isAuth.session_key,
        1,
        null
      );

      dispatch(updateCaptchaStatus(true));
      // navigate("/initialSearch");
    } catch (error: any) {
      setOpen(true);
      if (
        error?.response?.data?.data ===
        "Login Pending.Get method is not allowed."
      ) {
        setMessage("Invalid username or pasword");
      } else if (
        error?.response?.data?.data === "Client Subscription Expired"
      ) {
        setMessage("Subscription Expired, Please contact ");
        setLink("jcoordinator@informaticsglobal.ai");
      } else {
        setMessage(error?.response?.data?.data);
      }
      //   setMessage('Invalid username or password.')
      dispatch(loginFail(error.message));
    }
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    SSOLogin();
  }, []);

  return (
    <div>
      {message && (
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {message}
              {message == "Subscription Expired, Please contact " && (
                <a href={link} target="_blank">
                  {link}
                </a>
              )}
            </Alert>
          </Snackbar>
        </Stack>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner isLoading={isLoading} />
      </div>
    </div>
  );
};

export default SSO;
